import { createTheme } from '@mui/material/styles';
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyStrong: true;
    bodyLight: true;
    bodyMediumStrong: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tabs: true;
  }
}
const ZecodeTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontFamily: 'var(--bodyText-font-family)',
          borderRadius: '0.3rem',
          width: 'max-width',
        },
        sizeSmall: {
          fontSize: '11px',
          padding: '2px 8px',
        },
        sizeMedium: {
          fontSize: '12px',
          padding: '3px 10px',
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            boxShadow: 'none',
            ':hover': {
              boxShadow: 'none',
              background: 'var(--first-color)',
            },
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'var(--bodyText-font-family)',
        },
      },
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontSize: '24px',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            fontSize: '20px',
            fontWeight: 600,
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            fontSize: '16px',
            fontWeight: 400,
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            fontSize: '14px',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            fontSize: '12px',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'h6' },
          style: {
            fontSize: '10px',
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontSize: '12px',
            fontweight: 300,
          },
        },
        {
          props: { variant: 'body2' },
          style: {
            fontSize: '10px',
          },
        },
        {
          props: { variant: 'bodyStrong' },
          style: {
            fontSize: '12px',
            fontWeight: '600',
          },
        },
        {
          props: { variant: 'bodyLight' },
          style: {
            fontSize: '12px',
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'subtitle1' },
          style: {
            fontSize: '10px',
          },
        },
        {
          props: { variant: 'bodyMediumStrong' },
          style: {
            fontSize: '12px',
            fontWeight: '400',
          },
        },
        {
          props: { variant: 'subtitle2' },
          style: {
            fontSize: '13px',
            fontWeight: '400',
          },
        },
      ],
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 16,
          height: 16,
        },
      },
    },
  },
});

export default ZecodeTheme;
