import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Components
import CandidateInvitation from './candidates/candidateInvitation';
import AssessmentDetails from './assessment/assessmentDetails';
import Assessment from './assessment/assessment';
import AssessmentCompleted from './assessment/assessmentCompleted';
import Help from './help/help';
import CandidateNotFound from './candidateNotFound';

// CSS
import '../css/candidate.css';

// Static
import { RouterNames } from '../static/constants';
import InterviewCoding from './candidates/interviewCoding';
import InterviewDetails from './candidates/interviewDetails';
import InterviewCompleted from './candidates/interviewCompleted';
import CandidateCreation from './candidates/candidateCreation';
import useDisableInspect from './common/useDisableInspect';
import LeaveInterview from './candidates/leaveInterview';

const CandidateRoutes = () => {
  const handleDisable = useDisableInspect();

  // todo -- for Full screen mode when exam started.
  // const handle = useFullScreenHandle();
  // const container = handle.node;
  // const fullScreenHandler = () => {
  //     handle.enter();
  // };

  handleDisable();

  return (
    <>
      <Routes>
        <Route
          path={`${RouterNames.candidateInvitation}/:id/:enterpriseId`}
          element={<CandidateInvitation />}
        />
        <Route path={RouterNames.newCandidate} element={<CandidateCreation />} />
        <Route path={`${RouterNames.assessmentDetails}/:id`} element={<AssessmentDetails />} />
        <Route path={`${RouterNames.assessment}/:id/:sessionToken`} element={<Assessment />} />

        <Route path={`${RouterNames.interview}/:id`} element={<InterviewCoding />} />

        <Route path={`${RouterNames.interviewDetails}/:id`} element={<InterviewDetails />} />

        <Route path={`${RouterNames.interviewCompleted}/:id`} element={<InterviewCompleted />} />

        <Route path={`${RouterNames.leaveInterview}/:id`} element={<LeaveInterview />} />

        <Route path={`${RouterNames.assessmentCompleted}/:id`} element={<AssessmentCompleted />} />
        <Route path={`${RouterNames.help}/:id`} element={<Help />} />
        <Route path={'/'} element={<CandidateNotFound />} />
      </Routes>
    </>
  );
};

export default CandidateRoutes;
