import React from 'react';
import styled from 'styled-components';
import { commonStrings } from '../../static/commonStrings';
import { Box, SxProps } from '@mui/material';
import { colors } from '../../static/constants';

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  gap: 68px;
  padding: 0px 0px 30px 10px;
`;

const ZecodeUrl = () => {
  const zeCodeUrls = [
    {
      urlName: commonStrings.assessment.assessmentStartPage.mailText,
      urlAddress: `${process.env.REACT_APP_ZECODE_LANDING_PAGE}`,
    },
    {
      urlName: commonStrings.assessment.assessmentStartPage.termsAndConditions,
      urlAddress: `${process.env.REACT_APP_ZECODE_TERMS_OF_SERVICE}`,
    },
    {
      urlName: commonStrings.assessment.assessmentStartPage.privacyPolicy,
      urlAddress: `${process.env.REACT_APP_ZECODE_PRIVACY_POLICY}`,
    },
    {
      urlName: commonStrings.assessment.assessmentStartPage.contactUs,
      urlAddress: `${process.env.REACT_APP_ZECODE_CONTACT_US}`,
    },
  ];
  return (
    <LinksContainer>
      {zeCodeUrls.map((obj: any, data: any) => (
        <Box
          component={'a'}
          key={data}
          sx={styledLinkSx}
          href={obj.urlAddress}
          rel='noreferrer'
          target='_blank'
        >
          {obj.urlName}
        </Box>
      ))}
    </LinksContainer>
  );
};

export default ZecodeUrl;

const styledLinkSx: SxProps = {
  color: colors.grey,
  fontfamily: 'poppins',
  fontSize: '14px',
  fontweight: '400',
  lineheight: '21px',
  letterspacing: '0em',
  textalign: 'left',
  cursor: 'pointer',
};
