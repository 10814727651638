import React, { useEffect, useState } from 'react';
import { Typography, DialogContentText, SxProps } from '@mui/material';
import { commonStrings } from '../../static/commonStrings';
import {
  colors,
  ProctorStatus,
  StorageKeys,
  warningsLeft,
} from '../../static/constants';
import { getItem, setItem } from '../../util/storage';
import { useParams } from 'react-router';
import { updatePlagarismDetails } from '../../api/requests/plagarism';
import ZecodeDialog from './zecodeDialog';
interface CameraPopupProps {
  isModal: string | null;
  capture: () => void;
}


const CameraDialog: React.FC<CameraPopupProps> = ({
  isModal,
  capture,
}) => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  const [headingText, setHeadingText] = useState<string>(
    getItem(StorageKeys.ProctorStatus + id) ? getItem(StorageKeys.ProctorStatus + id)! : '',
  );

  const handleClose = () => {
    if (isModal === null) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [movementDetectionsCount, setMovementDetectionsCount] = useState<number>(
    getItem(StorageKeys.WarningCount + id) ? Number(getItem(StorageKeys.WarningCount + id)) : 0,
  );


  const showPopup = () => {
    if (isModal !== null && isModal !== '' && isModal !== ProctorStatus.Camera) {
      if (movementDetectionsCount < warningsLeft) {
        capture();
      }
      const warningCount = getItem(StorageKeys.WarningCount + id);
      if (warningCount && parseInt(warningCount) >= warningsLeft) {
        setOpen(false);
       
      } 
      const updatedCount = movementDetectionsCount + 1;
      setItem(
        StorageKeys.WarningCount + id,
        warningCount ? (parseInt(warningCount) + 1).toString() : '1',
      );
      updatePlagarismDetails(null, id!, null, null, updatedCount);
      setMovementDetectionsCount(updatedCount);
    }
  };

  useEffect(() => {
    if (!open) {
      showPopup();
      if (isModal === ProctorStatus.Camera) {
        setHeadingText(commonStrings.popUp.popUpMessages.cameraWarning);
        setItem(StorageKeys.ProctorStatus + id, commonStrings.popUp.popUpMessages.cameraWarning);
      } else if (isModal == ProctorStatus.MultipleFaces) {
        setHeadingText(commonStrings.popUp.popUpMessages.multipleFacesWarning);
        setItem(
          StorageKeys.ProctorStatus + id,
          commonStrings.popUp.popUpMessages.multipleFacesWarning,
        );
      } else if (isModal == ProctorStatus.NoFace) {
        setHeadingText(commonStrings.popUp.popUpMessages.noFaceWarning);
        setItem(StorageKeys.ProctorStatus + id, commonStrings.popUp.popUpMessages.noFaceWarning);
      }
      if (isModal !== '' && isModal !== null) {
        handleOpen();
      }
    }
  }, [isModal]);

  return (
    <>
    {open ? (   
      <ZecodeDialog
        title={commonStrings.popUp.popupTitles.attention}
        hideActionButtons={false}
        actionButton={handleClose}
        actionButtonTitle='OK'
        disable={isModal !== null}
      >
        <DialogContentText   sx={dialogContextSx(isModal)}
        >
           <Typography variant='h5'  sx={{ color: colors.midnightIndigo }}>
            {headingText}
          </Typography>
          <Typography variant='bodyMediumStrong'>
          {headingText === commonStrings.popUp.popUpMessages.noFaceWarning &&
             commonStrings.popUp.popUpMessages.proctorNote}
             {headingText === commonStrings.popUp.popUpMessages.multipleFacesWarning &&
             commonStrings.popUp.popUpMessages.proctorNoteMultipleFaces}
          </Typography>
        </DialogContentText>
      </ZecodeDialog>
     
    ) : null}
    </>
  );
};

export default CameraDialog;

const dialogContextSx:(isModal:string | null) => SxProps= (isModal) => ({
   display: 'flex', 
   flexDirection: 'column' ,
   gap: isModal === 'Camera' ? 0 : 3,
});

