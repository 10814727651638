import { getData } from '../apiUtils';
import { client } from '../client';
import Endpoints from '../Endpoints';

interface CandidateDetails {
  assessmentId: string;
  name: string;
  email: string;
}

export const createCandidateDetails = (candidateDetails: CandidateDetails) => {
  return client.post(`${Endpoints.createCandidate}`, candidateDetails).then(getData);
};
