import React from 'react';
import styled from 'styled-components';
import ZecodeUrl from './common/ZecodeUrl';
import { ZecodeText } from './common/zecodeText';

const paperPinIcon = '/images/LinkExpiredIcon.gif';

const LinkExpiredScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 100%;
  height: 100vh;
  background: #000821;
`;

const ZecodeAndEvaluateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  flex: 15%;
  padding: 2rem 3rem;
  && img {
    width: 11.2%;
  }
  && span {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #f2f2f2;
  }
`;

const LinkExpiredWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 75%;
`;

const LinkExpiredCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(25% + 5rem);
  height: fit-content;
  padding: 20px;
  background: #ebebeb;
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  && span {
    font-family: var(---font-family);
    font-style: normal;
  }
`;

const LinkAccessExpiredWrapper = styled.span`
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #000821;
`;

const PleaseContactAdminWrapper = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0d2225;
`;

const TermsAndConditionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8rem;
  padding: 2rem 2.4rem;
  && a {
    font-family: var(--bodyText-font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ebebeb;
  }
`;

const CandidateNotFound = () => {
  return (
    <LinkExpiredScreenWrapper>
      <ZecodeAndEvaluateWrapper>
        <ZecodeText isHeader={true} />
        <span>Evaluate and hire best candidates !</span>
      </ZecodeAndEvaluateWrapper>
      <LinkExpiredWrapper>
        <LinkExpiredCardWrapper>
          <img src={paperPinIcon} alt='paper pin icon' />
          <LinkAccessExpiredWrapper>Link access expired !</LinkAccessExpiredWrapper>
          <PleaseContactAdminWrapper>
            Uh-Oh! Looks like the Assessment you are trying to access has expired. Please contact
            your Admin
          </PleaseContactAdminWrapper>
        </LinkExpiredCardWrapper>
      </LinkExpiredWrapper>
      <TermsAndConditionsWrapper>
        <ZecodeUrl />
        <ZecodeText />
      </TermsAndConditionsWrapper>
    </LinkExpiredScreenWrapper>
  );
};

export default CandidateNotFound;
