import { client } from '../client';
import Endpoints from '../Endpoints';

interface addSubmitTimeTypes {
  testId: string;
  markedForReview: string[];
  manualSubmit?: boolean;
}
export const fetchAssessmentTimer = (testId: string) => {
  return client.post(`${Endpoints.endTimeUrl}`, {
    testId,
  });
};

export const addSubmitTime = (addSubmitTimeProps: addSubmitTimeTypes) => {
  return client.post(`${Endpoints.addSubmitTimeUrl}`, {
    addSubmitTimeProps,
  });
};

export const setTime = (testId: string, extraTime: number, testDuration: number) => {
  return client.post(`${Endpoints.setTimerUrl}`, {
    testId,
    extraTime,
    testDuration,
  });
};
