import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { InterviewQuestion } from '../Types/Index';

interface SandpackComponentProps {
  activeQuestion?: InterviewQuestion;
  isInterviewer: boolean;
  reactQuillChange?: any;
}

const InterviewQuestionEditor: React.FC<SandpackComponentProps> = ({
  activeQuestion,
  isInterviewer,
  reactQuillChange,
}) => {
  return (
    <>
      {
        /*Candidate's Editor */
        !isInterviewer && (
          <ReactQuill
            key={activeQuestion?._id}
            theme='snow'
            placeholder={'Question will be displayed here'}
            value={activeQuestion?.questionDescription}
            preserveWhitespace
            readOnly={true}
          />
        )
      }
      {
        /*Interviewer's Editor  */
        isInterviewer && (
          <>
            <ReactQuill
              key={activeQuestion?._id}
              theme='snow'
              placeholder={'Enter your question here'}
              onChange={reactQuillChange}
              value={activeQuestion?.questionDescription || ''}
              preserveWhitespace
              readOnly={!isInterviewer}
            />
          </>
        )
      }
    </>
  );
};

export default InterviewQuestionEditor;
