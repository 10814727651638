import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, RouterNames, sectionStatus, zecodeLogo } from '../../static/constants';

// CSS

import '../../css/assessmentCompleted.css';

// Static
import { commonStrings } from '../../static/commonStrings';
import { Box, Button, SxProps } from '@mui/material';
import { getInterviewCandidate, updateInterviewStatus } from '../../api/requests/interview';
import { ThemeConfigurationType } from '../Types/Index';
import { setProfileDetailSettings } from '../../util/util';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import ZecodeUrl from '../common/ZecodeUrl';
import { ZecodeText } from '../common/zecodeText';
import { ThemeContext } from '../context/themeContext';

//Images
const BackgroundImage = '/images/interviewsBackground.svg';

const Footer = styled.div`
  padding: 0vh 4vw 0vh 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: var(--bodyText-font-family);
`;

const InterviewText = styled.p`
  margin-bottom: 20px;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #ffffff;
`;

const LinkText = styled.p`
  font-family: var(--bodyText-font-family);
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e0e0e0;
`;

const InvitedText = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #e0e0e0;
`;

const WelcomeText = styled.p`
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #ffffff;
`;

const Hr = styled.hr`
  width: 500px;
  height: 0px;
  margin-bottom: 30px;
  border: 1px solid #e0e0e0;
`;

const InterviewDetails = () => {
  const { setThemeColor } = useContext(ThemeContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [profileDetails, setProfileDetails] = useState<ThemeConfigurationType>();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [enterpriseName, setEnterpriseName] = useState('');
  const [interviewStarted, setInterViewStarted] = useState<boolean>(false);
  const [isCamera, setIsCamera] = useState<boolean>(false);
  const [date, setDate] = useState<string>('');

  const updateStatus = async () => {
    try {
      id && updateInterviewStatus({ id, status: sectionStatus.inProgress });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickProceedHandler = async () => {
    try {
      if (id) {
        const response = await getInterviewCandidate({ id });
        const isCandidateActive = response?.data?.data?.isActive;

        if (isCandidateActive === true) {
          await updateStatus();
          window.open(`${RouterNames.interview}/${id}`, '_blank');
        } else {
          navigate('/');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCandidateDetails = async () => {
    try {
      if (id) {
        const response = await getInterviewCandidate({ id });
        const enterpriseID = response?.data?.data?.enterpriseId;
        const themeResponse = enterpriseID && (await getEnterpriseTheme(enterpriseID));
        themeResponse &&
          themeResponse?.data &&
          themeResponse?.data?.data &&
          setProfileDetailSettings(themeResponse?.data?.data);
        setThemeColor(themeResponse?.data?.data?.firstColor ?? '');
        setProfileDetails(themeResponse?.data?.data);
        const currentDateTime = new Date();
        const inputDateTimeString = response.data?.data?.scheduledDateTime;
        const inputDateTime = new Date(inputDateTimeString);

        const formattedDateWithMonthName = inputDateTime.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
        setDate(formattedDateWithMonthName);

        if (inputDateTime <= currentDateTime) {
          setInterViewStarted(true);
        }
        if (response?.data?.data?.status === sectionStatus.submitted) {
          navigate('/');
        }
        const enterprise = await getEnterpriseById(response?.data?.data?.enterpriseId);
        setEnterpriselogo(enterprise?.data?.data?.imageURL);
        setEnterpriseName(enterprise?.data?.data?.name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCandidateDetails();
  }, []);

  useEffect(() => {
    const checkCamera = async () => {
      try {
        const details = await navigator.mediaDevices.enumerateDevices();
        const detailsData = details.find((obj: any) => obj.kind === 'videoinput');
        if (!detailsData?.deviceId || !detailsData?.groupId) {
          setIsCamera(false);
        } else {
          setIsCamera(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    checkCamera();
  }, []);

  return (
    <Box sx={containerSx}>
      <Box sx={logoWrapperSx}>
        <Box
          component={'img'}
          src={
            profileDetails?.imageURL
              ? profileDetails?.imageURL
              : enterpriseLogo
                ? enterpriseLogo
                : zecodeLogo
          }
          alt='logo'
          sx={logoSx}
        />
      </Box>
      <Box sx={interviewTextContentSx}>
        <WelcomeText>
          Welcome to ze
          <Box component={'b'} sx={{ color: colors.blue }}>
            [
          </Box>
          code
          <Box component={'b'} sx={{ color: colors.blue }}>
            ]
          </Box>
        </WelcomeText>
        <InterviewText>
          {commonStrings.interview.interviewStartPage.interviewPlatform}
        </InterviewText>
        <InvitedText>
          {commonStrings.interview.interviewStartPage.invitedText}
          <Box sx={enterpriseNameSx}>{enterpriseName}</Box>
        </InvitedText>
        <Hr />
        {interviewStarted ? (
          <>
            <LinkText>{commonStrings.interview.interviewStartPage.invitedLink}</LinkText>
            <Button
              size='medium'
              variant='outlined'
              sx={interviewProceedButtonSx}
              onClick={onClickProceedHandler}
            >
              {commonStrings.interview.interviewStartPage.interviewJoinButton}
            </Button>
          </>
        ) : (
          <LinkText>Interview starts on {date}</LinkText>
        )}
      </Box>
      <Footer>
        <ZecodeUrl />
        <ZecodeText />
      </Footer>
    </Box>
  );
};

export default InterviewDetails;

const logoSx: SxProps = {
  borderRadius: '40px',
  width: '65px',
  height: '65px',
};

const interviewTextContentSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const enterpriseNameSx: SxProps = {
  fontWeight: 500,
  paddingBottom: '30px',
};

const interviewProceedButtonSx: SxProps = {
  width: '20%',
  marginTop: '14px',
  fontSize: '14px',
  color: colors.white,
  textTransform: 'inherit',
  padding: '4px 0px',
};

const containerSx: SxProps = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '30px',
  backgroundImage: `url(${BackgroundImage})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const logoWrapperSx: SxProps = {
  display: 'flex',
  marginRight: '80px',
  marginTop: ' 20px',
  marginLeft: '38px',
};
