import { useEffect, useState } from 'react';
import { getAllProjectSetups } from '../../api/requests/codeSnippet';
export interface ProjectSetupProps {
  domainID: number;
  setup: {
    title: string;
    template: string;
    code: string;
  };
  type: string;
  isHidden: boolean;
}

export default function useGetAllProjectSetups({
  isAssessments = false,
}: Partial<{ isAssessments?: boolean }>) {
  const [allProjectsData, setAllProjectsData] = useState<ProjectSetupProps[]>([]);

  const getAllProjectSetup = async () => {
    const allProjectsResponse = await getAllProjectSetups({ isAssessments });
    setAllProjectsData(allProjectsResponse?.data);
  };
  useEffect(() => {
    getAllProjectSetup();
  }, []);

  return allProjectsData;
}
