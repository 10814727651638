import { useContext } from 'react';
import { DialogContext } from '../context/dialogContext';

export const useZecodeDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useZecodeDialog must be used within a DialogProvider');
  }
  return context;
};
