/* eslint-disable @typescript-eslint/no-non-null-assertion */
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RemoveIcon from '@mui/icons-material/Remove';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Checkbox, FormControlLabel, Link, SxProps, Typography } from '@mui/material';
import sdk from '@stackblitz/sdk';
import { Project } from '@stackblitz/sdk/typings/interfaces';
import { VM } from '@stackblitz/sdk/typings/VM';
import axios from 'axios';
import * as R from 'ramda';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import Showdown from 'showdown';
import styled from 'styled-components';
import '../../css/candidateCoding.css';
import { ProjectSetupProps } from '../hooks/useGetAllProjectSetups';
// Common
import IndicationPopover from '../common/IndicationPopover';
// Static
import {
  SandpackCodeEditor,
  SandpackFileExplorer,
  SandpackLayout,
  SandpackPreview,
  SandpackProvider,
  SandpackStack,
  useSandpack,
} from '@codesandbox/sandpack-react';
import { nightOwl } from '@codesandbox/sandpack-themes';
import { Box, Button } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import rehypeKatex from 'rehype-katex';
import gfm from 'remark-gfm';
import math from 'remark-math';
import { client } from '../../api/client';
import Endpoints from '../../api/Endpoints';
import { getSaveSQLScore } from '../../api/requests/assessmentCredits';
import { getCandidateDetails } from '../../api/requests/candidate';
import { fetchInitialCodeSnippet } from '../../api/requests/codeSnippet';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { updatePlagarismDetails } from '../../api/requests/plagarism';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { commonStrings } from '../../static/commonStrings';
import {
  AssessmentType,
  baseUrl,
  colors,
  compilerTypes,
  datamodelsUrl,
  IDE_TEMPLATES,
  Languages,
  MAX_SCORE,
  mongoDBDatamodelsUrl,
  noRowsFound,
  noServerResponse,
  PROJECT_SETUP_TYPES,
  SECTIONS,
  TempLanguages,
  Time,
  zecodeLogo,
} from '../../static/constants';
import { clearLogForJsOrTs, runJsCode } from '../../static/frontendExecutors';
import { getItem, setItem } from '../../util/storage';
import { generateKey, getIdeType, setProfileDetailSettings } from '../../util/util';
import { LanguageType } from '../assessment/assessment';
import AssessmentDialogContent from '../common/assessmentDialogContent';
import CountDown from '../common/countDown';
import { DropdownByGroup } from '../common/Dropdown';
import { ThemeContext } from '../context/themeContext';
import { useZecodeDialog } from '../hooks/useZecodeDialog';
import PopupMessage from '../popups/popupMessage';
import { ThemeConfigurationType } from '../Types/Index';
import {
  blackDropdownArrow,
  DropDownContainer,
  DropDownHead,
  dropdownIcon,
  IDEList,
  Label,
  ListOptions,
} from './interviewStyles';
import MonacoComponent from './MonacoComponent';

// Image
const resetIcon = '/images/resetIcon.svg';
const lightThemeIcon = '/images/lightThemeIcon.svg';
const darkThemeIcon = '/images/darkTheme.svg';
const timeIcon = '/images/timeIconWhite.svg';
const backIcon = '/images/backIcon.svg';
const RunCodeIcon = '/images/runCodeIcon.svg';
const NameIcon = '/images/whiteNameIcon.svg';

const TWENTY_SECONDS = 20 * Time.SECOND;
export const Loading = '/images/Load Icon - F.gif';
export const Loader = '/images/assessmentLoader.gif';

// Styles
const StyledHeader = styled(Box)`
  display: flex;
  height: 4vw;
  width: 100%;
  top: 0;
  align-items: center;
  background-color: #0d2225;
  z-index: 1;
  padding: 0px 40px 0px 20px;
  box-shadow: 0 0 4px var(--first-color) !important;
  @media (max-width: 1350px) {
    height: 5vw;
  }
`;

const Container = styled(Box)<{ $isDark: boolean }>`
  display: flex;
  max-width: 100vw;
  background-color: ${(props) => (props.$isDark ? '#FcFcFc' : '#1E1B31')};
  min-height: 100vh;
`;

const LeftContainer = styled(Box)`
  width: 26vw;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 0px 20px 10px;
  @media (max-width: 1350px) {
    width: 23vw;
  }
  max-height: calc(100vh - 52px);
  overflow: auto;
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 50px;
  }
`;

const RightContainer = styled(Box)`
  padding-top: 5px;
  overflow: auto;
  ::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 50px;
  }
`;

const SubCaption = styled(Box)<{ $isDark: boolean; hasTextDecor?: boolean }>`
  color: ${(props) => (props.$isDark ? '#404040' : '#999999')};
  font-family: var(--bodyText-font-family);
  font-size: 12px;
  font-weight: 400;
  padding: 0px 0px 10px 0px;
  text-decoration: ${(props) => props.hasTextDecor && 'underline'};
  cursor: ${(props) => props.hasTextDecor && 'pointer'};
  &:hover {
    color: ${(props) => props.hasTextDecor && 'var(--first-color)'};
  }
`;

const InputCaption = styled(Box)<{ $isDark: boolean }>`
  color: ${(props) => (props.$isDark ? '#404040' : '#D9D9D9')};
  font-family: var(--bodyText-font-family);
  font-size: 12px;
  font-weight: 500;
  padding: 0px 10px;
`;

const EnterInputCaption = styled(Box)<{ $isDark: boolean }>`
  color: ${(props) => (props.$isDark ? '#404040' : '#f1f1f1')};
  font-size: 14px;
  font-weight: 500;
  font-family: var(--bodyText-font-family);
  padding: 0px 10px;
`;

const ZesstaLogo = styled.img`
  padding: 15px;
  width: 68px;
  height: 68px;
  border-radius: 50%;
`;

const StyledButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

const StyledSideContainer = styled.div<{
  $isDark?: boolean;
  $isDatabaseQuestion?: boolean;
}>`
  width: ${(props) => (!props.$isDatabaseQuestion ? '25.7vw' : '52vw')};
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px 0px 10px 0px;
  overflow: hidden;
  background-color: ${(props) => (props.$isDark ? '#FcFcFc' : '#1E1B31')};
  color: #ffffff;
  @media (max-width: 1350px) {
    width: 27vw;
  }
  max-height: calc(100vh - 52px);
  height: 500px;
  min-height: calc(100vh - 52px);
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 50px;
  }
`;

const StyledMainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: calc(100vh - 52px);
  padding: 10px;
  overflow: hidden;
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 50px;
  }
`;

export const StyledLink = styled(Link)<{ $isDark?: boolean }>`
  color: #999999 !important;
  &:hover {
    color: var(--first-color) !important;
  }
`;

const CountDownContainer = styled(Box)`
  display: flex;
  padding: 3px 2px 3px 10px;
  border: none;
  border-radius: 8px;
  margin-right: 200px;
`;

const TimeLeftWrapper = styled(Box)`
  width: 250px;
  display: flex;
  align-items: center;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #f1f1f1;
`;

const OutputOuterWrapper = styled(Box)<{
  $isDark: boolean;
  $background: boolean;
}>`
  height: 375px;
  min-height: 375px;
  background: ${(props) => (props.$background ? 'white' : props.$isDark ? 'white' : '#15182b')};
  box-shadow: ${(props) =>
    props.$isDark ? '1px 1px 8px rgba(0, 0, 0, 0.08)' : '0px 4px 16px rgba(0, 0, 0, 0.17)'};
  border-radius: 4px;
  padding: 20px;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin: 0px 10px;
`;

const DatabaseOutputOuterWrapper = styled(Box)<{
  $isDark: boolean;
  $background: boolean;
}>`
  height: 375px;
  min-height: 375px;
  background: ${(props) => (props.$background ? 'white' : props.$isDark ? 'white' : '#15182b')};
  box-shadow: ${(props) =>
    props.$isDark ? '1px 1px 8px rgba(0, 0, 0, 0.08)' : '0px 4px 16px rgba(0, 0, 0, 0.17)'};
  border-radius: 4px;
  padding: 20px;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
`;

const QuestionContainer = styled(Box)<{ $isDark: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 581px;
  min-height: 581px;
  background: ${(props) => (props.$isDark ? 'white' : '#15182b')};
  box-shadow: ${(props) =>
    props.$isDark ? '1px 1px 8px rgba(0, 0, 0, 0.08)' : '0px 4px 16px rgba(0, 0, 0, 0.17)'};
  border-radius: 4px;
  padding: 20px;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  overflow: auto;
  margin-right: 10px;
  ::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 50px;
    margin: 10px 10px 10px 10px !important;
  }
  && img {
    display: flex;
    width: 300px;
    border: 1px solid black;
    margin-top: 20px;
  }
  && code {
    white-space: pre-line;
  }
`;

const OutputWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const OutputContainer = styled(Box)<{ $isDark: boolean }>`
  color: ${(props) => (props.$isDark ? 'black' : '#999999')};
  background: ${(props) => (props.$isDark ? '#f2f2f2' : 'black')};
  height: 90%;
  padding: 10px;
  overflow: auto;
  ::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 50px;
  }
`;
const DatabaseOutputContainer = styled(Box)<{ $isDark: boolean }>`
  color: ${(props) => (props.$isDark ? '#999999' : '999999')};
  height: 90%;
  padding-right: 10px;
  overflow: auto;
  word-break: break-all;
  white-space: normal;
  ::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 50px;
  }
`;

const QuestionNumber = styled(Box)<{ $isDark: boolean }>`
  display: flex;
  flex-wrap: wrap;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => (props.$isDark ? '#000821' : '#f1f1f1')};
`;

const EditorDiv = styled.div`
  position: sticky;
  top: 0;
  display: flex;
`;

const CodingContainer = styled.div<{
  $isDark?: boolean;
  $isDatabaseQuestion?: boolean;
}>`
  width: ${(props) => (!props.$isDatabaseQuestion ? '54vw' : '28vw')};
  display: flex;
  padding: 10px 1vw 0px 1vw;
  flex-direction: column;
  gap: 20px;
  background-color: ${(props) => (props.$isDark ? '#F2F2F2' : '#1e1e1e')};
  @media (max-width: 1350px) {
    width: 50vw;
  }
  && .monaco-editor .margin {
    background-color: ${(props) => (props.$isDark ? '#F2F2F2' : '#1e1e1e')};
  }
  && .monaco-editor-background {
    background-color: ${(props) => (props.$isDark ? '#F2F2F2' : '#1e1e1e')};
  }
  && canvas.minimap-decorations-layer {
    background-color: ${(props) => (props.$isDark ? '#F2F2F2' : '#1e1e1e')};
    display: none !important;
  }
  && canvas.decorationsOverviewRuler {
    background-color: ${(props) => (props.$isDark ? '#F2F2F2' : '#1e1e1e')};
    width: 3px !important;
  }
  && .monaco-scrollable-element > .scrollbar > .slider {
    background-color: var(--first-color);
    width: 3px !important;
  }
  && .monaco-editor .vertical {
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#1E1B31;')};
    width: 3px !important;
  }
  && .mtk1 {
    color: ${(props) => (props.$isDark ? 'black' : 'white')};
  }
  && .mtk6 {
    color: #569cd6;
  }
  && .mtk22 {
    color: #3dc9b0;
  }
  && .mtk20 {
    color: #ce9178;
  }
`;

const PreWrapper = styled.pre<{
  $isDark?: boolean;
  $background?: boolean;
}>`
  word-break: break-all;
  white-space: pre-line;
  padding: 0.5rem 0rem 0.5rem 0.5rem;
  height: 95%;
  background: ${(props) => (props.$background ? 'black' : '#f2f2f2')};
  border-radius: 10px;
  overflow-x: auto;
  overflow-y: auto;
  color: ${(props) => (props.$background ? '#FFFFFF' : '#1e1e1e')};
  &::-webkit-scrollbar {
    width: 1px !important;
    height: 3px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    width: 1px !important;
    height: 3px !important;
  }
  && table thead tr th,
  table tbody tr td {
    color: ${(props) => (props.$background ? '#FFFFFF' : '#1e1e1e')};
    border: ${(props) => (props.$background ? '1px dashed #FFFFFF' : '1px dashed #1e1e1e')};
    padding: 5px;
  }
  && table {
    border-collapse: collapse;
  }
`;

const CodingButtonsContainer = styled(Box)<{ $isDark?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  padding-top: 5px;
  align-items: center;
  background-color: ${(props) => (props.$isDark ? ' #F2F2F2' : '#1e1e1e')};
`;

const ButtonsContainer = styled.div<{ $isDark?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  padding: 10px;
  background-color: ${(props) => (props.$isDark ? '#FcFcFc' : '#1E1B31')};
  cursor: pointer;
`;

const QuestionHeader = styled(Box)<{ $isDark?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.$isDark ? '#FcFcFc' : '#1E1B31')};
  position: sticky;
  top: 0;
  padding-right: 10px;
  gap: 15px;
`;

const ThemeButtonWrapper = styled(Box)<{ $isDark?: boolean }>`
  height: 26px !important;
  width: 123px;
  background: none;
  border: 1px solid ${(props) => (props.$isDark ? '#404040' : '#F1F1F1')} !important;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 0px 5px;
  align-items: center;
  color: ${(props) => (props.$isDark ? '#404040' : '#F1F1F1')} !important;
  font-size: 12px !important;
  font-weight: 500;
  font-family: var(--bodyText-font-family);
  &:hover {
    cursor: pointer;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: auto;
`;

export const ImageWrapper = styled.img`
  width: 12px;
  height: 12px;
`;

export const LoaderImageWrapper = styled.img`
  width: 40px;
  height: 40px;
`;

const DropDownAndThemeWrapper = styled(Button)`
  width: 500px;
`;

const ThemeIcon = styled.img<{ width: string; height: string }>`
  width: ${(props) => (props.width ? props.width : '15px')} !important;
  height: ${(props) => (props.height ? props.height : '15px')} !important;
`;

const ResetButton = styled.button`
  padding: 5px !important;
  background: #d9d9d9;
  border-radius: 4px;
  border: none;
  display: flex;
  gap: 3px;
  justify-content: space-around;
  align-items: center;
  color: #404040;
  font-size: 12px;
  font-weight: 500;
  font-family: var(--bodyText-font-family);
  height: 26px;
  width: 107px;
`;

const ButtonText = styled(Box)`
  display: flx;
  justify-content: center;
  align-items: center;
  color: #000821;
  font-size: 12px;
  font-weight: 400;
  font-family: var(--bodyText-font-family);
`;

const RunButton = styled(Box)`
  background: #28b894;
  border-radius: 4px;
  width: 99px;
  height: 26px;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
`;

const RunTestCasesButton = styled(Box)`
  background: #000412;
  border-radius: 4px;
  border: 0.75px solid var(--first-color);
  width: 115px;
  height: 26px;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--bodyText-font-family);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 10px var(--first-color);
  }
`;

const StyledButtonWrapper = styled.div`
  && {
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    gap: 30px;
    padding: 0px 10px;
    @media (max-width: 1840px) {
      padding: 15px 10px;
    }
  }
`;

const BackAssessmentWrapper = styled(Box)`
  cursor: pointer;
  color: #b4b4b4;
  display: flex;
  align-items: center;
  padding-left: 20px;
  width: 200px;
  gap: 10px;
`;

const ZesstaLogoWrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding-left: 30px;
`;

const DetailsAndLogoWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
`;

const image = {
  height: '225px',
  border: ' 1px solid black',
  marginLeft: ' -8px',
  width: '340px',
};

const BackAssessmentButton = styled(Box)`
  text-transform: none !important;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #f1f1f1 !important;
`;

const Span = styled.span`
  margin: 6px;
`;

const Input = styled.input`
  display: block;
  border-radius: 4px;
  padding: 8px;
  margin: 10px;
  font-family: var(--bodyText-font-family);
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
`;

const Name = styled(Box)`
  display: flex;
  align-items: center;
  font-family: var(--bodyText-font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #f1f1f1;
`;

const StyledFormControlLabel = styled(FormControlLabel)<{ $isDark?: boolean }>`
  && {
    width: 136px;
    height: 20px;
    .MuiTypography-root {
      font-family: var(--bodyText-font-family);
      font-weight: 400;
      font-size: 12px;
      color: ${(props) => (props.$isDark ? '#000821' : '#f1f1f1')};
      margin-top: 4px;
    }
  }
`;

const SquareRoundedIconWrapper = styled(SquareRoundedIcon)`
  color: #d9d9d9;
  bottom: 0;
  margin-left: 5px;
  margin-top: 4px;
`;

const CheckBoxIconWrapper = styled(CheckBoxIcon)`
  color: var(--first-color);
  bottom: 0;
  margin-left: 5px;
  margin-top: 4px;
`;

const IframWrapper = styled.iframe`
  border: none;
  height: 90%;
  width: 108%;
`;

const ThemeWrapper = styled(Box)`
  white-space: nowrap;
`;
const SQLEditor = styled.div<{ $theme: any }>`
  color: ${(props) => (!props.$theme ? 'white' : 'black')};
  text-align: left;
  margin-left: 20px;
  font-family: var(--bodyText-font-family);
`;

enum StackblitzDefaults {
  ID = 'stackblitz',
  OPEN_FILE = 'index.html',
  HEIGHT = '530',
}

export enum EditorNames {
  Monaco = 'Monaco',
  Stackblitz = 'Stackblitz',
}
export type EditorTypes = `${EditorNames}`;

export type IntervalType = {
  ideId?: number;
  intervalId: NodeJS.Timer;
  editorType: EditorTypes;
};
export type CallBackDetails<T> = {
  value: T;
  callback: (value?: T) => void;
};

export type handleSnapshotSaveIntervalsFnType = <T>(
  editorType: EditorTypes,
  callbackDetails: CallBackDetails<T>,
  timeout?: number,
) => void;

export interface CandidateCodingProps {
  questions: any;
  popupSection: string;
  setQuestions: any;
  setPopupSection: any;
  setPopupTrigger: any;
  updateResponses: any;
  remainingTime?: number;
  timeLeft: string;
  currentTime?: number;
  candidateId?: string;
  languages: LanguageType[];
  setIsCodeEditorOpen: any;
  markedForReviewQuestions: string[];
  onClickCheckBoxHandler: (id: string) => void;
  leftTab: boolean;
  setLeftTab: (arg0: boolean) => void;
  candidateName?: string;
  totalTime: number;
  isDatabaseQuestion?: boolean;
  malpracticeFound: boolean;
  theme: boolean;
  setTheme: any;
  setMobileResolution?: React.Dispatch<React.SetStateAction<MobileSizeProps>>;
  allProjectSetups: ProjectSetupProps[];
}

const SandpackFileExplorerWrapper = styled(SandpackFileExplorer)`
  & div:nth-child(2) {
    padding-left: 18px;
  }
`;

const SandpackCodeEditorWrapper = styled(SandpackCodeEditor)`
  .sp-c-gGbYbQ::-webkit-scrollbar-thumb {
    background-color: var(--first-color);
    border-radius: 50px;
  }
  .sp-c-gGbYbQ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  .sp-c-gGbYbQ::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 50px;
    margin-left: 10px;
  }
`;

const CodeViewer = (props: any) => {
  const { sandpack } = useSandpack();
  const { files, activeFile } = sandpack;
  props.setCodeFiles(files);
  props.setActiveFiles(activeFile);
  return <pre></pre>;
};

export type MobileSizeProps = {
  label: string;
  width: number;
  height: number;
};
export const mobileSizes = [
  { label: 'Samsung Galaxy S8', width: 390, height: 750.66 },
  { label: 'iPhone 12 Pro', width: 370, height: 801 },
  { label: 'Samsung Galaxy S7', width: 360, height: 640 },
];

const CandidateCoding: React.FC<CandidateCodingProps> = ({
  questions,
  setQuestions,
  updateResponses,
  remainingTime,
  timeLeft,
  currentTime,
  languages,
  setIsCodeEditorOpen,
  candidateId,
  markedForReviewQuestions,
  onClickCheckBoxHandler,
  leftTab,
  setLeftTab,
  candidateName,
  totalTime,
  isDatabaseQuestion,
  malpracticeFound,
  theme,
  setTheme,
  setMobileResolution,
  allProjectSetups,
}) => {
  const { showDialog } = useZecodeDialog();
  const enterpriseId = getItem('enterpriseId');
  const { setThemeColor } = useContext(ThemeContext);
  const { id } = useParams();
  const [output, setOutput] = useState<any>();
  const [monacoCode, setMonacoCode] = useState<string>();
  const [resetCode, setResetCode] = useState(false);
  const [enterpriseLogo, setEnterpriseLogo] = useState('');
  const [codeSnippet, setCodeSnippet] = useState('');
  const [displayLanguages, setDisplayLanguages] = useState<ProjectSetupProps[]>([]);
  const [codeFiles, setCodeFiles] = useState<any>();
  const [activeFile, setActiveFile] = useState<any>();
  const challengeIdx = questions.questionNo.codingChallenges;
  const databaseChallengeIdx = questions.questionNo.databaseQuestions;
  const [vms, setVms] = useState<VM>();
  const {
    answer: {
      codingChallenges: answerCodingChallenges,
      databaseQuestions: answerDatabaseQuestions,
    },
    question: {
      codingChallenges: questionCodingChallenges,
      databaseQuestions: questionDatabaseQuestions,
    },
  } = questions;
  const [selectedIDE, setSelectedIDE] = useState<number>(
    isDatabaseQuestion ? 13 : answerCodingChallenges[challengeIdx]?.IDE,
  );
  const selectedIdeTypeFromIde = allProjectSetups?.find(
    (projects) => projects.domainID === selectedIDE,
  )?.type; 
  const [selectedIdeType, setSelectedIdeType] = useState<string>(selectedIdeTypeFromIde || '');
  const [tempSelectIDE, setTempSelectIDE] = useState<number>();
  const [languageDisplay, setLanguageDisplay] = useState(false);
  const [isLeftTab, setIsLeftTab] = useState(false);
  const [customInput, setCustomInput] = useState<string[]>([]);
  const [profileDetails, setProfileDetails] = useState<ThemeConfigurationType>();

  const dropDownRef = React.useRef();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>();
  const [activeQuestionNo, setActiveQuestionNo] = useState(0);
  const [selectedIDEValue, setSelectedIDEValue] = useState('');
  const [editorValue, setEditorValue] = useState<string>('');
  const [showERDiagram, setShowERDiagram] = useState<boolean>(false);
  const [sqlQueryError, setSqlQueryError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [testCasesLoader, setTestCasesLoader] = useState<boolean>(false);
  const [renderMonaco, setRenderMonaco] = useState<string>(generateKey());
  const [mobileSelectedOption, setMobileSelectedOption] = useState<MobileSizeProps>(mobileSizes[0]);
  const JsOrTs: boolean = selectedIDE === 2 || selectedIDE === 4;
  let updatedValue: string | undefined, previousValue: string;
  useEffect(() => {
    const selectedIDEType = allProjectSetups?.find(
      (projects) => projects.domainID === selectedIDE,
    )?.type;
    setSelectedIdeType(selectedIDEType!);
  }, [allProjectSetups, allProjectSetups.length, selectedIDE]);
  const handlePaste = async (event: any) => {
    setItem('copiedCode', event.clipboardData.getData('text'));

    const questionsCopy = { ...questions };
    const { codingChallenges, databaseQuestions } = questionsCopy.answer;
    let copiedCode;
    if (getItem('copiedCode') !== null) {
      copiedCode = getItem('copiedCode');
    } else {
      copiedCode = '';
    }
    let pasteCount = getItem('pasteCount') ? Number(getItem('pasteCount')) : 0;
    const copyCount = getItem('copyCount') ? Number(getItem('copyCount')) : 0;
    if (
      !isDatabaseQuestion &&
      copiedCode &&
      copiedCode.length > 0 &&
      !codingChallenges[challengeIdx]?.code[1]?.includes(copiedCode)
    ) {
      if (leftTab) {
        pasteCount += 1;
        setItem('pasteCount', pasteCount.toString());
        setLeftTab(false);
        try {
          await updatePlagarismDetails(null, id!, copyCount, pasteCount, null);
        } catch (error) {
          console.error(error);
        }
      }
    } else if (
      isDatabaseQuestion &&
      copiedCode &&
      copiedCode.length > 0 &&
      !databaseQuestions[databaseChallengeIdx]?.code[1]?.includes(copiedCode)
    ) {
      if (leftTab) {
        pasteCount += 1;
        setItem('pasteCount', pasteCount.toString());
        setLeftTab(false);
        try {
          await updatePlagarismDetails(null, id!, copyCount, pasteCount, null);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  // Setters & Fetchers

  /**
   * Storing Data in `questions.answer`
   * @param vms Stackblitz Virtual Machine
   */
  const saveSnapshot = async (vms?: VM) => {
    try {
      if (!R.isNil(vms) && selectedIDE < 5) {
        const snapshot = await vms.getFsSnapshot();
        const questionsCopy = { ...questions };
        const { codingChallenges } = questionsCopy.answer;
        // Todo --- for playback
        const areCodingChallengesCodeAvailable = codingChallenges[challengeIdx].code;
        if (areCodingChallengesCodeAvailable) {
          codingChallenges[challengeIdx].code.splice(0, 0, snapshot);
        } else {
          codingChallenges[challengeIdx].code = [snapshot];
        }
        codingChallenges[challengeIdx].files = snapshot;
        await updateResponses(questionsCopy.answer);
        setQuestions(questionsCopy);
        setMobileResolution && setMobileResolution?.(mobileSelectedOption);
      }
    } catch (error) {
      console.error(`Snapshot couldn't be saved`, error);
    }
  };

  const checkAndEmbedStackblitz = (
    codingChallenges: Array<Project & { data: Array<Pick<Project, 'files'>> }>,
  ) => {
    if (codingChallenges[challengeIdx] && selectedIDE < 5) {
      sdk
        .embedProject(StackblitzDefaults.ID, R.omit(['data'], codingChallenges[challengeIdx]), {
          openFile: StackblitzDefaults.OPEN_FILE,
          height: StackblitzDefaults.HEIGHT,
          hideNavigation: true,
          forceEmbedLayout: true,
        })
        .then((vm) => {
          const timeOut = TWENTY_SECONDS;
          setVms(vm);
          const interval = setInterval(() => saveSnapshot(vm), timeOut);
          return () => clearInterval(interval);
        })

        .catch((error) => console.error('Could not save code snapshot', error));
    }
  };

  /**
   * Fetches project setup data from backend & stores it in answers state.
   */
  const fetchProjectSetupData = async () => {
    try {
      const questionsCopy = { ...questions };
      const { codingChallenges } = questionsCopy.answer;
      let response;
      if (!isDatabaseQuestion && questionsCopy.question.codingChallenges[challengeIdx]) {
        response = await fetchInitialCodeSnippet(
          selectedIDE,
          questionsCopy.question.codingChallenges[challengeIdx]._id,
        );
      }
      setCustomInput(response?.data?.input);
      if (
        response?.data?.domainID === 1 ||
        response?.data?.domainID === 2 ||
        response?.data?.domainID === 4 ||
        response?.data?.domainID === 17 ||
        response?.data?.domainID === 18
      ) {
        setCodeSnippet(response?.data?.setup.files);
      } else {
        setCodeSnippet(response?.data?.setup.code[0]);
      }
      if (
        response &&
        (!codingChallenges[challengeIdx]?.IDE ||
          codingChallenges[challengeIdx]?.IDE !== selectedIDE)
      ) {
        //For coding questions
        codingChallenges[challengeIdx] = response?.data?.setup;
        codingChallenges[challengeIdx].files = response?.data?.setup?.files;
        codingChallenges[challengeIdx].IDE = selectedIDE;
        codingChallenges[challengeIdx].startTime =
          new Date().getHours() + ':' + new Date().getMinutes();
        codingChallenges[challengeIdx].id =
          questionsCopy.question.codingChallenges[challengeIdx]._id;
      }
      setQuestions(questionsCopy);
      setMobileResolution?.(mobileSelectedOption);
      setRenderMonaco(generateKey());
    } catch (error) {
      console.error(error);
    }
  };

  const saveSQLScore = async (score: number) => {
    try {
      await getSaveSQLScore(
        candidateId!,
        questionDatabaseQuestions[databaseChallengeIdx]?._id,
        score,
        SECTIONS.databaseQuestions,
      );
    } catch (error) {
      console.error(error);
    }
  };

  // Handlers
  const languagedisplayHandler = () => {
    setLanguageDisplay((languageDisplay) => !languageDisplay);
  };

  const closeDropdownHandler = () => {
    if (languageDisplay) {
      setLanguageDisplay(false);
    }
  };

  const codeExecute = async () => {
    try {
      setLoading(true);
      if (!isDatabaseQuestion) {
        const sourcecode: string = answerCodingChallenges[challengeIdx]?.code[0];
        const response = await axios.post(`${baseUrl}/code`, {
          language: answerCodingChallenges[challengeIdx]?.template,
          sourcecode,
          dev: true,
          questionId: questionCodingChallenges[challengeIdx]?._id,
          customInput,
          assessmentType: AssessmentType.Candidate,
        });
        if (response?.data?.exitCode === 0) setOutput(response?.data?.stdout);
        else setOutput(response?.data?.stderr);
      } else {
        setOutput(null);
        const sourcecode: string = answerDatabaseQuestions[databaseChallengeIdx]?.code[0];
        const response = await client.post(`${Endpoints.executeSQLCodeURL}`, {
          language: Languages[12].value,
          sourcecode,
        });
        if (response?.data?.status === 'error') {
          setSqlQueryError(true);
          setOutput(noServerResponse);
          setLoading(false);
          return;
        }
        if (response?.data?.errno) {
          setSqlQueryError(true);
          setOutput(response?.data?.sqlMessage);
          setLoading(false);
          saveSQLScore(0);
          return;
        }
        if (response?.data.length === 0) {
          setSqlQueryError(true);
          setOutput(noRowsFound);
          const dbQuestion = await client.get(
            `${baseUrl}/databaseQuestion/${questionDatabaseQuestions[databaseChallengeIdx]._id}`,
          );
          const expectedResultJson = dbQuestion?.data?.expectedResultJson;
          if (expectedResultJson === noRowsFound) {
            saveSQLScore(MAX_SCORE);
          } else {
            saveSQLScore(0);
          }
          setLoading(false);
          return;
        }
        setSqlQueryError(false);
        setOutput(response?.data);
        const dbQuestion = await client.get(
          `${baseUrl}/databaseQuestion/${questionDatabaseQuestions[databaseChallengeIdx]._id}`,
        );
        const expectedResultJson = dbQuestion?.data?.expectedResultJson;
        if (expectedResultJson === JSON.stringify(response?.data)) {
          saveSQLScore(MAX_SCORE);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const testCasesExecute = async () => {
    try {
      setTestCasesLoader(true);
      const response = await axios.post(`${baseUrl}/code/evaluate`, {
        language: answerCodingChallenges[challengeIdx]?.template,
        sourcecode: answerCodingChallenges[challengeIdx]?.code[0],
        questionId: questionCodingChallenges[challengeIdx]?._id,
        dev: true,
        candidateId,
      });
      if (response) {
        setOutput(
          <span>
            <Box sx={testCases}>
              <TaskAltIcon /> Total test cases passed <RemoveIcon />{' '}
              {response?.data?.totalTestCasesPassed}
            </Box>
            <br />
            <Box sx={{ ...testCases, color: colors.testCasesFailed }}>
              <CancelOutlinedIcon /> Total test cases failed <RemoveIcon />{' '}
              {response?.data?.totalTestCases - response?.data?.totalTestCasesPassed}
            </Box>
          </span>,
        );
      } else {
        setOutput('No test case Passed');
      }
      setTestCasesLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  const codeSubmitEventHandler = async (dialogClose: () => void) => {
    const challengeIndex = questions?.questionNo.codingChallenges;
    localStorage.setItem('currentQuestionIndex', '-1');
    const newData = { ...questions };
    if (newData.answer.codingChallenges[challengeIndex]) {
      newData.answer.codingChallenges[challengeIndex].codeSubmit = true;
    }
    setQuestions(newData);
    setIsCodeEditorOpen(false);
    dialogClose();
    await updateResponses();
    if ((selectedIDE ?? 0) >= 5 && selectedIDE !== 17 && !isDatabaseQuestion) {
      await testCasesExecute();
    }
    setRenderMonaco(generateKey());
  };

  const codeSubmitEvent = async () => {
    const questionsCopy = { ...questions };
    const { codingChallenges } = questionsCopy.answer;
    if (!isDatabaseQuestion) {
      codingChallenges[challengeIdx].endTime =
        new Date().getHours() + ':' + new Date().getMinutes();
    } else {
      codeExecute();
    }
    await updateResponses();
    showDialog({
      title: commonStrings.popUp.popupTitles.submitCode,
      hideActionButtons: true,
      content: (closeDialog) => (
        <AssessmentDialogContent
          closeDialog={closeDialog}
          onClickYesEvent={codeSubmitEventHandler}
          popupIsActiveMessage={commonStrings.popUp.popUpMessages.submitCodeText}
          dialogText={commonStrings.popUp.popUpMessages.submitCodeDescription}
        />
      ),
    });
  };

  const backToAssessmentEventHandler = async (dialogClose: () => void) => {
    localStorage.setItem('currentQuestionIndex', '-1');
    setIsCodeEditorOpen(false);
    dialogClose();
    await updateResponses();
    if ((selectedIDE ?? 0) >= 5 && selectedIDE !== 17 && !isDatabaseQuestion) {
      await testCasesExecute();
    }
    setRenderMonaco(generateKey());
  };

  const backToAssessmentEvent = async () => {
    if (isDatabaseQuestion) {
      codeExecute();
    }
    if (selectedIDE < 5) {
      await saveSnapshot(vms);
    }
    await updateResponses();
    showDialog({
      title: commonStrings.popUp.popupTitles.goBack,
      hideActionButtons: true,
      content: (closeDialog) => (
        <AssessmentDialogContent
          closeDialog={closeDialog}
          onClickYesEvent={backToAssessmentEventHandler}
          popupIsActiveMessage={commonStrings.popUp.popUpMessages.backToAssessmentText}
          dialogText={commonStrings.popUp.popUpMessages.backToAssessmentDescription}
        />
      ),
    });
  };

  const resetCodeEventHandler = async (dialogClose: () => void) => {
    const challengeIndex = questions?.questionNo.codingChallenges;
    const databasechallengeIndex = questions?.questionNo.databaseQuestions;
    const newData = { ...questions };
    setOutput('');
    newData?.answer?.databaseQuestions[databasechallengeIndex]?.code?.splice(0, 0, '');
    if (typeof newData?.answer?.codingChallenges[challengeIndex]?.code === 'string') {
      newData.answer.codingChallenges[challengeIndex].code = codeSnippet;
    } else if (typeof newData?.answer?.codingChallenges[challengeIndex]?.code !== 'string') {
      newData?.answer?.codingChallenges[challengeIndex]?.code?.splice(0, 0, codeSnippet);
    }
    setQuestions(newData);
    setRenderMonaco(generateKey());
    dialogClose();
  };

  const onResetCodeEvent = () => {
    showDialog({
      title: commonStrings.popUp.popupTitles.resetCode,
      hideActionButtons: true,
      content: (closeDialog) => (
        <AssessmentDialogContent
          closeDialog={closeDialog}
          onClickYesEvent={resetCodeEventHandler}
          popupIsActiveMessage={commonStrings.popUp.popUpMessages.resetCodeText}
          dialogText={commonStrings.popUp.popUpMessages.changeIDEdescription}
        />
      ),
    });
  };

  const onMonacoEditorChange = (value?: string) => {
    setMonacoCode(value);

    // Set the current question index in the localstorage
    const currentQuestionIndex = localStorage.getItem('currentQuestionIndex');
    // If currentQuestionIndex not present in the localstorage
    if (!isDatabaseQuestion) {
      if (!currentQuestionIndex) {
        localStorage.setItem('currentQuestionIndex', challengeIdx);
      }
      // If present but not the updated one update the currentQuestionIndex
      if (currentQuestionIndex && currentQuestionIndex !== challengeIdx) {
        localStorage.setItem('currentQuestionIndex', challengeIdx);
      }
    } else {
      if (!currentQuestionIndex) {
        localStorage.setItem('currentQuestionIndex', databaseChallengeIdx);
      }
      // If present but not the updated one update the currentQuestionIndex
      if (currentQuestionIndex && currentQuestionIndex !== databaseChallengeIdx) {
        localStorage.setItem('currentQuestionIndex', databaseChallengeIdx);
      }
    }

    const questionsCopy = { ...questions };
    const { codingChallenges, databaseQuestions } = questionsCopy.answer;
    if (!isDatabaseQuestion) {
      const areCodingChallengesCodeAvailable = codingChallenges[challengeIdx].code;
      if (areCodingChallengesCodeAvailable) {
        codingChallenges[challengeIdx].code.splice(0, 0, value);
      } else {
        codingChallenges[challengeIdx].code = [value];
      }
    } else {
      if (databaseQuestions[databaseChallengeIdx] == undefined) {
        databaseQuestions[databaseChallengeIdx] = {
          code: '',
          IDE: 13,
          startTime: new Date(),
          id: questionsCopy.question.databaseQuestions[databaseChallengeIdx]._id,
        };
      }
      const areDatabaseQuestionsCodeAvailable = databaseQuestions[databaseChallengeIdx].code;
      if (areDatabaseQuestionsCodeAvailable) {
        databaseQuestions[databaseChallengeIdx].code.splice(0, 0, value);
      } else {
        databaseQuestions[databaseChallengeIdx].code = [value];
      }
    }
    setQuestions(questionsCopy);
    setMobileResolution?.(mobileSelectedOption);
  };

  const debouncedOnChange = debounce(onMonacoEditorChange, 1000);

  const customInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const tempData = [...customInput];
    tempData[index] = event.target.value;
    setCustomInput(tempData);
  };

  const fetchProfileDetails = async () => {
    const response = await getEnterpriseTheme(enterpriseId!);

    response &&
      response?.data &&
      response?.data?.data &&
      setProfileDetailSettings(response?.data?.data);
    setThemeColor(response?.data?.data?.firstColor ?? '');

    setProfileDetails(response?.data?.data);
    setEnterpriseLogo(response?.data?.data?.imageURL);
  };

  const timeEnd = moment(timeLeft);

  const fetchUserDetails = async () => {
    const response = await getCandidateDetails(id!);
    const language = response.data.data[0].assessmentDetails.languages;
    // for existing assessments making sure to have python
    // ide in place of sql.
    if (language.includes(13)) {
      const sqlIndex = language.indexOf(13);
      language.splice(sqlIndex, 1);
      language.push(16);
    }
    let filteredProjects: ProjectSetupProps[] = [];
    const enterprise = await getEnterpriseById(
      response?.data?.data[0].assessmentDetails?.enterpriseId,
    );
    setEnterpriseLogo(enterprise?.data?.data?.imageURL);
    if (!isDatabaseQuestion) {
      filteredProjects = language.map((value: number) => {
        const filterProject = allProjectSetups.find((project) => project.domainID === value);
        return filterProject;
      });
    }
    setDisplayLanguages(filteredProjects);
  };
  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  const mathHandler = (text: any) => {
    return (
      <ReactMarkdown remarkPlugins={[math, gfm]} rehypePlugins={[rehypeKatex]}>
        {text}
      </ReactMarkdown>
    );
  };

  //If the Yes button is clicked in the PopUp displayed for the confirmation to change programming language,
  //the ide gets changed to the selected language.
  const ideChangeHandlerEvent = async (dialogClose: () => void, value?: string) => {
    const ideIdValue = Number(value!);
    const ideType = getIdeType({
      selectedIde: ideIdValue,
      allProjectSetups: allProjectSetups ?? [],
    });
    setSelectedIDE(ideIdValue);
    setOutput('');
    setRenderMonaco(generateKey());
    dialogClose();
    setSelectedIdeType(ideType);
  };

  // when the candidate tries to change the programming language ,a popUp will get displayed for the confirmation.
  const onIdeChangeHandlerEvent = (value: number) => {
    setSelectedIDEValue(`${value!}`);
    if (!selectedIDE) {
      setSelectedIDE(value);
    } else {
      showDialog({
        title: commonStrings.popUp.popupTitles.changeIDE,
        hideActionButtons: true,
        content: (closeDialog) => (
          <AssessmentDialogContent
            closeDialog={closeDialog}
            onClickYesEvent={ideChangeHandlerEvent}
            popupIsActiveMessage={commonStrings.popUp.popUpMessages.changeIDEText}
            dialogText={commonStrings.popUp.popUpMessages.changeIDEdescription}
            value={`${value!}`}
          />
        ),
      });
    }
  };

  const listof7: any = (data: any) =>
    data?.length > 5 ? [data?.slice(0, 5), ...listof7(data?.slice(5))] : [data];

  // Effects

  useEffect(() => {
    enterpriseId && fetchProfileDetails();
    fetchUserDetails();
  }, []);

  useEffect(() => {
    const section = localStorage.getItem('section');
    if (section === SECTIONS.databaseQuestions) {
      setSelectedIDE(13);
    }
  });

  useEffect(() => {
    const currentSection = localStorage.getItem('section');
    const index = localStorage.getItem('currentQuestionIndex');
    if (questions?.answer[`${currentSection}`][index!]?.IDE) {
      setSelectedIDE(questions.answer[`${currentSection}`][index!].IDE);
    }
  }, [questions]);

  useEffect(() => {
    fetchProjectSetupData();
  }, [selectedIDE]);

  useEffect(() => {
    const getData = setTimeout(() => {
      updateResponses();
    }, 2000);

    return () => clearTimeout(getData);
  }, [questions]);

  useEffect(() => {
    updateResponses();
  }, [codeFiles]);

  useEffect(() => {
    const handlePopState = async () => {
      await updateResponses();
    };
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  useEffect(() => {
    if (!selectedIDE) {
      setAnchorEl(dropDownRef.current);
    }
  }, []);

  useEffect(() => {
    if (malpracticeFound) {
      codeExecute();
      testCasesExecute();
    }
  }, [malpracticeFound]);

  const clickModal = () => {
    setShowERDiagram(true);
  };

  const closeModal = () => {
    setShowERDiagram(false);
  };

  const LanguageNoDropdown = () => {
    return (
      <ListOptions>
        {listof7(displayLanguages)?.map((data: any, index: number) => (
          <ul key={index}>
            {data?.map((item: any, num: number) => (
              <IDEList $active={true} key={num}>
                {item?.image && <img src={item?.image} height={16} width={16} alt='Icon' />}
                <span>{item?.data}</span>
              </IDEList>
            ))}
          </ul>
        ))}
        {displayLanguages.length === 0 &&
          !isDatabaseQuestion &&
          'No languages available to select from'}
      </ListOptions>
    );
  };

  /* To do for later */
  /* useEffect(() => {
    handleJSEditorChange(
      questions?.answer?.codingChallenges[challengeIdx]?.code,
      updatedValue,
      previousValue,
      selectedIDE,
      JsOrTs,
      questions,
      challengeIdx,
      setMonacoCode,
      setEditorValue,
      setQuestions,
      updateResponses,
      profileDetails?.firstColor ?? 'black'
    );
  }, [questions?.answer?.codingChallenges[challengeIdx]?.code, profileDetails]); */

  const language = Languages?.find((language) => language.id === selectedIDE);
  const template: string | undefined = language?.value;

  const intitalFileState = useMemo(() => {
    const result: { [key: string]: string } = {};
    const isIdeSame = questions?.answer?.codingChallenges[challengeIdx]?.IDE === selectedIDE;
    if (questions?.answer?.codingChallenges[challengeIdx]?.files && isIdeSame) {
      Object.entries(questions.answer.codingChallenges[challengeIdx].files).forEach(
        ([key, value]) => {
          result[key] = (value as { code: string })?.code;
        },
      );
    }
    return result;
  }, [questions, challengeIdx, selectedIDE]);

  const handleUpdation = (data: object[]) => {
    if (!data) return;
    setCodeFiles(data);
    setActiveFile(activeFile);
    const existingQuestions = questions;
    if (!existingQuestions.answer.codingChallenges[challengeIdx]) return;
    existingQuestions.answer.codingChallenges[challengeIdx].files = data;
    setQuestions(existingQuestions);
    setMobileResolution?.(mobileSelectedOption);
  };

  const selectedIdeName = allProjectSetups?.find((projects) => projects.domainID === selectedIDE);

  const LanguageList = () => {
    return !isDatabaseQuestion ? (
      <DropDownContainer onClick={languagedisplayHandler}>
        <DropDownHead className='dropdown-head' isDisplay={languageDisplay}>
          <Typography variant='body1'>
            {selectedIDE >= 0 ? selectedIdeName?.setup.title : 'Select IDE'}
          </Typography>
          <img
            src={
              window
                .getComputedStyle(document.documentElement)
                .getPropertyValue('--button-text-color') === '#000000'
                ? blackDropdownArrow
                : dropdownIcon
            }
            alt='icon'
          />
        </DropDownHead>
        {languageDisplay ? (
          <Box sx={dropDownMainStyles()}>
            <DropdownByGroup
              title='Backend'
              activeQuestionIde={questions[activeQuestionNo]?.IDE}
              filterName='backend'
              projectSetupList={displayLanguages!}
              onSelectedOption={onIdeChangeHandlerEvent}
            />
            <DropdownByGroup
              title='Database'
              activeQuestionIde={questions[activeQuestionNo]?.IDE}
              filterName='database'
              projectSetupList={displayLanguages!}
              onSelectedOption={onIdeChangeHandlerEvent}
            />
            <DropdownByGroup
              title='Frontend'
              activeQuestionIde={questions[activeQuestionNo]?.IDE}
              filterName='frontend'
              projectSetupList={displayLanguages!}
              onSelectedOption={onIdeChangeHandlerEvent}
            />
          </Box>
        ) : null}
      </DropDownContainer>
    ) : (
      <LanguageNoDropdown />
    );
  };
  return (
    <Box onClick={closeDropdownHandler}>
      <StyledHeader>
        <BackAssessmentWrapper onClick={backToAssessmentEvent}>
          <img src={backIcon} alt='backIcon' />
          <Typography color={colors.Alabaster} variant='body1'>
            Go Back
          </Typography>
        </BackAssessmentWrapper>
        <DetailsAndLogoWrapper>
          <NameContainer>
            <img src={NameIcon} alt='name' height={19} width={20} />
            <Typography variant='h3' color={colors.Alabaster}>
              {candidateName}
            </Typography>
          </NameContainer>
          <CountDownContainer>
            <TimeLeftWrapper>
              <img src={timeIcon} alt='time' />
              <Typography color={colors.Alabaster} variant='h3'>
                Time left :
              </Typography>
              <CountDown
                codeExecute={codeExecute}
                updateResponses={updateResponses}
                totalTime={totalTime}
                timeEnd={timeEnd}
                markedForReviewQuestions={markedForReviewQuestions}
                testCasesExecute={testCasesExecute}
              />
            </TimeLeftWrapper>
          </CountDownContainer>
          <ZesstaLogoWrapper>
            <ZesstaLogo
              src={
                profileDetails?.imageURL
                  ? profileDetails?.imageURL
                  : enterpriseLogo
                    ? enterpriseLogo
                    : zecodeLogo
              }
              alt='zecode'
            />
          </ZesstaLogoWrapper>
        </DetailsAndLogoWrapper>
      </StyledHeader>
      <Container $isDark={theme}>
        <LeftContainer>
          <>
            <QuestionHeader $isDark={theme}>
              <StyledFormControlLabel
                $isDark={theme}
                control={
                  <Checkbox
                    icon={<SquareRoundedIconWrapper fontSize='small' />}
                    checkedIcon={<CheckBoxIconWrapper fontSize='small' />}
                    checked={
                      markedForReviewQuestions?.length > 0
                        ? !isDatabaseQuestion
                          ? markedForReviewQuestions.includes(
                              questionCodingChallenges[challengeIdx]?._id,
                            )
                          : markedForReviewQuestions.includes(
                              questionDatabaseQuestions[databaseChallengeIdx]?._id,
                            )
                        : false
                    }
                    onChange={() => {
                      if (!isDatabaseQuestion) {
                        onClickCheckBoxHandler(questionCodingChallenges[challengeIdx]?._id);
                      } else {
                        onClickCheckBoxHandler(
                          questionDatabaseQuestions[databaseChallengeIdx]?._id,
                        );
                      }
                    }}
                  />
                }
                label={<Typography variant='h5'>Mark for review</Typography>}
              />
              {!isDatabaseQuestion && (
                <Typography variant='h3' color={colors.Alabaster}>
                  Question Number - {challengeIdx + 1}
                </Typography>
              )}
              {isDatabaseQuestion && (
                <Typography variant='h3' color={colors.Alabaster}>
                  Question Number - {databaseChallengeIdx + 1}
                </Typography>
              )}
            </QuestionHeader>
            <QuestionContainer $isDark={theme}>
              {!isDatabaseQuestion && (
                <>
                  <Box>{questionCodingChallenges[challengeIdx]?.title}</Box>
                  <Box>{mathHandler(questionCodingChallenges[challengeIdx]?.question)}</Box>
                </>
              )}
              {(isDatabaseQuestion || selectedIdeType === PROJECT_SETUP_TYPES.DATABASE) && (
                <>
                  <Box>{questionDatabaseQuestions[databaseChallengeIdx]?.title}</Box>
                  <Box>{questionDatabaseQuestions[databaseChallengeIdx]?.question}</Box>
                  <Box>
                    <StyledLink href='#' onClick={clickModal}>
                      Link To ER Diagram
                    </StyledLink>
                    <Modal
                      isOpen={showERDiagram}
                      onRequestClose={closeModal}
                      contentLabel='ER Diagram'
                      className='datamodelscontainer'
                    >
                      <img
                        className='datamodels'
                        src={
                          isDatabaseQuestion ? datamodelsUrl[0].url : mongoDBDatamodelsUrl[0].url
                        }
                        alt='datamodel'
                      />
                      <Button size='medium' variant='contained' onClick={closeModal}>
                        Close
                      </Button>
                    </Modal>
                  </Box>
                </>
              )}
            </QuestionContainer>
          </>
        </LeftContainer>
        <StyledMainContainer ref={dropDownRef}>
          {!selectedIDE && (
            <>
              <LanguageList />
              {!selectedIDE && (
                <IndicationPopover
                  anchorEl={anchorEl}
                  text='Please select an IDE for your Coding Question'
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                />
              )}
            </>
          )}
          {selectedIDE && (
            <EditorDiv onPaste={handlePaste}>
              <CodingContainer $isDark={theme} $isDatabaseQuestion={isDatabaseQuestion}>
                <CodingButtonsContainer $isDark={theme}>
                  {!isDatabaseQuestion && <LanguageList />}
                  {/* //Commented below code for future use
                  {template === 'reactnative' && 
                    <Autocomplete
                      disablePortal
                      size={"small"} 
                      id="combo-box-demo"
                      options={mobileSizes}
                      sx={{ width: 280 ,  "& .MuiOutlinedInput-root": {
                      border: "1px solid white",
                        // borderRadius: 10,
                    },
                  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: 10,
      color: 'white'
    },
                  }}
                      defaultValue={mobileSizes[0]}
                      disableClearable
                      renderInput={(params) => <TextField {...params}  variant='outlined' size='small'/>}
                      onChange={(event, value, reason, currSelection) => {
                        setMobileSelectedOption(value)
                      }}
                     />
                    } */}
                  <ThemeButtonWrapper $isDark={theme} onClick={() => setTheme(!theme)}>
                    {theme ? (
                      <>
                        <ThemeIcon
                          src={darkThemeIcon}
                          alt='dark theme'
                          width='12.22px'
                          height='12.22px'
                        />
                        <ThemeWrapper>Dark Theme</ThemeWrapper>
                      </>
                    ) : (
                      <>
                        <ThemeIcon
                          src={lightThemeIcon}
                          alt='light theme'
                          width='15px'
                          height='15px'
                        />
                        <ThemeWrapper>Light Theme</ThemeWrapper>
                      </>
                    )}
                  </ThemeButtonWrapper>
                  <DropDownAndThemeWrapper />
                  {!isDatabaseQuestion &&
                    selectedIDE &&
                    (selectedIdeType === PROJECT_SETUP_TYPES.DATABASE ||
                      selectedIdeType === PROJECT_SETUP_TYPES.BACK_END) && (
                      <StyledButtonContainer>
                        <ResetButton onClick={onResetCodeEvent}>
                          <ButtonText>Reset code</ButtonText>

                          <ThemeIcon
                            src={resetIcon}
                            alt='reset icon'
                            width='10px'
                            height='11.6px'
                          />
                        </ResetButton>
                      </StyledButtonContainer>
                    )}
                </CodingButtonsContainer>
                {isDatabaseQuestion && <SQLEditor $theme={theme}>SQL Editor</SQLEditor>}
                {selectedIDE &&
                (selectedIdeType === PROJECT_SETUP_TYPES.DATABASE ||
                  selectedIdeType === PROJECT_SETUP_TYPES.BACK_END) ? (
                  !isDatabaseQuestion ? (
                    <MonacoComponent
                      key={renderMonaco}
                      language={
                        selectedIDE === 6 || selectedIDE === 12 || selectedIDE === 16
                          ? 'python'
                          : answerCodingChallenges[challengeIdx]?.template
                      }
                      value={answerCodingChallenges[challengeIdx]?.code[0] || ''}
                      onChange={onMonacoEditorChange}
                      theme={theme}
                      path={answerCodingChallenges[challengeIdx]?.template}
                    />
                  ) : (
                    <MonacoComponent
                      value={
                        answerDatabaseQuestions[databaseChallengeIdx]
                          ? answerDatabaseQuestions[databaseChallengeIdx].code[0]
                          : ''
                      }
                      onChange={debouncedOnChange}
                      theme={theme}
                      path={answerCodingChallenges[challengeIdx]?.template}
                    />
                  )
                ) : null}
                {selectedIdeType === PROJECT_SETUP_TYPES.FRONT_END ? (
                  <div style={{ height: '100%', width: '74vw' }}>
                    <SandpackProvider
                      template={
                        template === IDE_TEMPLATES.REACT_NATIVE
                          ? IDE_TEMPLATES.REACT
                          : (template as any)
                      }
                      options={{ activeFile: activeFile }}
                      theme={nightOwl}
                      files={intitalFileState}
                    >
                      <SandpackStack>
                        <SandpackLayout>
                          <SandpackFileExplorerWrapper style={{ height: '100vh' }} />
                          <CodeViewer
                            codeFiles={codeFiles}
                            setCodeFiles={handleUpdation}
                            setActiveFiles={setActiveFile}
                          />
                          <SandpackCodeEditorWrapper
                            style={{ height: '85vh' }}
                            showTabs
                            showLineNumbers={true}
                            showInlineErrors
                            wrapContent
                            closableTabs
                            key={codeFiles}
                          />
                          {template === IDE_TEMPLATES.REACT_NATIVE ? (
                            <Box
                              sx={reactNativeTemplateStyles({
                                height: mobileSelectedOption?.height,
                                width: mobileSelectedOption?.width,
                              })}
                            >
                              <SandpackPreview
                                style={{ height: '100%', width: '100%', borderRadius: '40px' }}
                                showOpenInCodeSandbox={false}
                              />
                            </Box>
                          ) : (
                            <SandpackPreview
                              style={{ height: '550px', width: '400px' }}
                              showOpenInCodeSandbox={false}
                            />
                          )}
                        </SandpackLayout>
                      </SandpackStack>
                    </SandpackProvider>
                  </div>
                ) : null}
              </CodingContainer>
              <StyledSideContainer $isDark={theme} $isDatabaseQuestion={isDatabaseQuestion}>
                <ButtonsContainer $isDark={theme}>
                  {selectedIDE &&
                    (selectedIdeType === PROJECT_SETUP_TYPES.DATABASE ||
                      selectedIdeType === PROJECT_SETUP_TYPES.BACK_END) && (
                      <RunButton
                        onClick={() => {
                          selectedIdeType === PROJECT_SETUP_TYPES.DATABASE ||
                          selectedIdeType === PROJECT_SETUP_TYPES.BACK_END
                            ? codeExecute()
                            : runJsCode(editorValue);
                        }}
                      >
                        {' '}
                        {loading ? (
                          <ImageWrapper src={Loading} />
                        ) : (
                          <ThemeIcon
                            src={RunCodeIcon}
                            alt='runCode'
                            width='7.33px'
                            height='7.88px'
                          />
                        )}
                        <span>Run code</span>
                      </RunButton>
                    )}
                  {(selectedIdeType === PROJECT_SETUP_TYPES.DATABASE ||
                    selectedIdeType === PROJECT_SETUP_TYPES.BACK_END) &&
                    !isDatabaseQuestion && (
                      <RunTestCasesButton onClick={testCasesExecute}>
                        {testCasesLoader ? (
                          <ImageWrapper src={Loading} />
                        ) : (
                          <ThemeIcon
                            src={RunCodeIcon}
                            alt='runCode'
                            width='7.33px'
                            height='7.88px'
                          />
                        )}
                        Run test cases
                      </RunTestCasesButton>
                    )}
                </ButtonsContainer>
                <RightContainer>
                  {!isDatabaseQuestion &&
                    selectedIDE &&
                    (selectedIdeType === PROJECT_SETUP_TYPES.BACK_END ||
                      selectedIdeType === PROJECT_SETUP_TYPES.DATABASE) && (
                      <EnterInputCaption $isDark={theme}>
                        Enter your input parameters here
                      </EnterInputCaption>
                    )}
                  {customInput?.map((input, index) => (
                    <Box key={index}>
                      <InputCaption $isDark={theme}>input {index + 1} :</InputCaption>
                      <Input
                        type='text'
                        key={index}
                        placeholder='Enter input parameter here'
                        value={input}
                        onChange={(event) => customInputChangeHandler(event, index)}
                      />
                    </Box>
                  ))}
                  {(selectedIdeType === PROJECT_SETUP_TYPES.BACK_END ||
                    selectedIdeType === PROJECT_SETUP_TYPES.DATABASE) &&
                    !isDatabaseQuestion && (
                      <OutputOuterWrapper $isDark={theme} $background={false}>
                        <OutputWrapper>
                          <SubCaption $isDark={theme}>Output console</SubCaption>
                          <SubCaption
                            $isDark={theme}
                            onClick={() => {
                              setOutput('');
                            }}
                            hasTextDecor={true}
                          >
                            Clear Log
                          </SubCaption>
                        </OutputWrapper>
                        <OutputContainer $isDark={theme}>{output}</OutputContainer>
                      </OutputOuterWrapper>
                    )}
                </RightContainer>
                {isDatabaseQuestion && (
                  <DatabaseOutputOuterWrapper $isDark={theme} $background={false}>
                    <OutputWrapper>
                      <SubCaption $isDark={theme}>Output console</SubCaption>
                      <SubCaption
                        $isDark={theme}
                        onClick={() => {
                          setOutput('');
                        }}
                        hasTextDecor={true}
                      >
                        Clear Log
                      </SubCaption>
                    </OutputWrapper>
                    <DatabaseOutputContainer $isDark={theme}>
                      <PreWrapper $isDark={theme} $background={!theme}>
                        {output && output[0] !== 'E' && !sqlQueryError ? (
                          <table>
                            <thead>
                              <tr>
                                {output
                                  ? Object.keys(output[0])?.map((obj, index) => (
                                      <th key={index}>{obj}</th>
                                    ))
                                  : ''}
                              </tr>
                            </thead>
                            <tbody>
                              {output?.length > 0 &&
                                output?.map((obj: any, rum: any) => (
                                  <tr key={rum}>
                                    {Object.entries(obj)?.map((item: any, ind) => (
                                      <td key={ind}>
                                        {item[0].includes('DATE') || item[0].includes('FROM')
                                          ? moment(item[1]).format('DD-MM-YYYY')
                                          : item[1]}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          output
                        )}
                      </PreWrapper>
                    </DatabaseOutputContainer>
                  </DatabaseOutputOuterWrapper>
                )}
                {selectedIDE && JsOrTs && (
                  <OutputOuterWrapper $isDark={theme} $background={false}>
                    <OutputWrapper>
                      <SubCaption $isDark={theme}>Output console</SubCaption>
                      <SubCaption
                        $isDark={theme}
                        onClick={() => clearLogForJsOrTs(true)}
                        hasTextDecor={true}
                      >
                        Clear Log
                      </SubCaption>
                    </OutputWrapper>
                    <div id='log'></div>
                    <div id='error'></div>
                  </OutputOuterWrapper>
                )}
                {selectedIDE && selectedIdeType === PROJECT_SETUP_TYPES.FRONT_END && !JsOrTs && (
                  <OutputOuterWrapper
                    $isDark={theme}
                    $background={selectedIDE === 1 || selectedIDE === 17 ? true : false}
                  >
                    <OutputWrapper>
                      <SubCaption $isDark={theme}>Output console</SubCaption>
                      <SubCaption
                        $isDark={theme}
                        onClick={() => clearLogForJsOrTs(false)}
                        hasTextDecor={true}
                      >
                        Clear Log
                      </SubCaption>
                    </OutputWrapper>
                    <IframWrapper id='output' title='output' />
                  </OutputOuterWrapper>
                )}

                <StyledButtonWrapper>
                  {selectedIDE &&
                    (selectedIdeType === PROJECT_SETUP_TYPES.BACK_END ||
                      selectedIdeType === PROJECT_SETUP_TYPES.DATABASE) && (
                      <Button
                        size='medium'
                        variant='contained'
                        onClick={() => {
                          codeSubmitEvent();
                        }}
                        title={!selectedIDE ? 'Select an IDE' : ''}
                        disabled={selectedIDE ? false : true}
                      >
                        Submit
                      </Button>
                    )}
                </StyledButtonWrapper>
              </StyledSideContainer>
            </EditorDiv>
          )}
        </StyledMainContainer>
      </Container>
    </Box>
  );
};

export default CandidateCoding;

const reactNativeTemplateStyles = (mobileSelectedOption: { width: number; height: number }) => {
  return {
    position: 'relative',
    height: 'calc(100vh - 180px)',
    margin: 'auto',
    border: '16px black solid',
    borderTopWidth: '60px',
    borderBottomWidth: '60px',
    borderRadius: '36px',
    aspectRatio: 9 / 18,
    m: 1,
    '&::before': {
      content: '""',
      display: 'block',
      width: '60px',
      height: '5px',
      position: 'absolute',
      top: '-30px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#333',
      borderRadius: '10px',
    },
    '&::after': {
      content: '""',
      display: 'flex',
      width: '35px',
      height: '35px',
      position: 'absolute',
      bottom: '-65px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#333',
      borderRadius: '50%',
    },
  };
};

const testCases: SxProps = {
  color: colors.testCasesPassed,
  display: 'flex',
  alignItems: 'center',
  gap: '3px',
};

export const dropDownMainStyles = (selectedIDE?: number) => {
  return {
    display: 'flex',
    color: '#f1f1f1',
    position: 'absolute',
    gap: '15px',
    backgroundColor: '#15182b',
    borderRadius: '4px',
    margin: '1px 0px',
    padding: '10px',
    fontSize: '12px',
    flexDirection: 'row',
    zIndex: selectedIDE === 26 ? 5 : 1,
    '& p': {
      fontWeight: 500,
      marginBottom: '10px',
    },
  };
};
