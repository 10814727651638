import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import Webcam from 'react-webcam';
import * as faceapi from 'face-api.js';

import {
  detectionIntervalSeconds,
  modelUrl,
  multipleFacesDetectionSeconds,
  noFaceDetectionSeconds,
  ProctorStatus,
} from '../../static/constants';
import { s3ImageUrl, s3UrlDetails } from '../../api/requests/getS3Url';
import { updateProctor } from '../../api/requests/proctoring';
import CameraDialog from '../common/cameraDialog';

interface ProctorProps {
  align: string;
  markedForReviewQuestions: string[];
  setMalpracticeFound: any;
  updateResponses: any;
}

const ProctorContainer = styled(Box)<{ $align: boolean }>`
  position: absolute;
  right: 80px;
  bottom: ${(props) => (props.$align ? '60px' : '0px')};
  left: ${(props) => props.$align && '25px'};
  width: 200px;
  && canvas {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0px;
    left: 0px;
  }
`;
const DragBox = styled(Box)`
  cursor: move;
  position: relative;
`;

const Proctor: React.FC<ProctorProps> = ({
  align,
  markedForReviewQuestions,
  setMalpracticeFound,
  updateResponses,
}) => {
  const params = useParams();
  const divMove: any = document.getElementById('proctorContainerId');
  const webcamBox: any = document.getElementById('protcorWebcamId');
  const webcamRef = useRef<any>(null);
  const cameraStart = useRef(false);
  const proctorWarning = useRef(true);
  const [faceDetails, setFaceDetails] = useState<any>([]);
  const [isCamera, setIsCamera] = useState<string | null>(null);
  const totalTimeCount = 10;
  const [countTimer, setCountTimer] = useState<number>(totalTimeCount);
  const [multipleFaceTimer, setMultipleFaceTimer] = useState<number>(totalTimeCount);

  const videoConstraints = {
    width: 420,
    height: 280,
    facingMode: 'user',
  };
  let canvas: any;
  let count = 1;

  const capture = async () => {
    // To extract candidate name from email.
    const candidateNameFromEmail = localStorage.getItem('candidateName');
    const imageSrc = webcamRef?.current?.getScreenshot();
    const decodedImage = window.atob(imageSrc?.split('base64,')[1]);
    const uint8Array = new Uint8Array(decodedImage.length);
    for (let i = 0; i < decodedImage.length; i++) {
      uint8Array[i] = decodedImage.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: 'image/png' });
    const fileBlob = new File([blob], `snapshot${count++}.png`);
    const key = `ProctorImages/${candidateNameFromEmail}_${params?.id}/${new Date()
      .toDateString()
      .replaceAll(' ', '_')}_${new Date()
      .toLocaleTimeString()
      .replaceAll(' ', '_')}_${params?.id}_${fileBlob?.name?.replaceAll(' ', '')}`;

    const response: any = await s3UrlDetails({ key });

    if (response?.status === 'success') {
      await s3ImageUrl(response?.imageURL, fileBlob);
      const png = response?.imageURL?.split('png')[0]?.concat('png');
      const snapShotDetails = {
        imageURL: png,
        time: new Date().toLocaleTimeString(),
        faceDetection: faceDetails,
      };
      await updateProctor(params.id!, snapShotDetails);
    }
  };

  const faceDetectionHandler = () => {
    if (!proctorWarning.current) {
      setIsCamera(ProctorStatus.NoFace);
      totalTimeCount && setCountTimer(totalTimeCount);
    }
  };

  const multipleFaceDetectionHandler = () => {
    if (!proctorWarning.current) {
      setIsCamera(ProctorStatus.MultipleFaces);
      totalTimeCount && setMultipleFaceTimer(totalTimeCount);
    }
  };

  let consecutiveNoFaceCount = 0;
  let consecutiveMultFaceCount = 0;

  const startDetection = async () => {
    setInterval(async () => {
      const detections =
        webcamBox &&
        (await faceapi
          ?.detectAllFaces(webcamBox, new faceapi.TinyFaceDetectorOptions())
          ?.withFaceLandmarks(true)
          ?.withFaceExpressions()
          ?.withAgeAndGender());
      const resizedDetections =
        detections &&
        faceapi?.resizeResults(detections, {
          width: divMove?.clientWidth,
          height: divMove?.clientHeight,
        });
      resizedDetections && resizedDetections?.length > 0 && setFaceDetails(resizedDetections);
      if (resizedDetections?.length === 0 && proctorWarning.current) {
        consecutiveNoFaceCount++;
        if (consecutiveNoFaceCount >= noFaceDetectionSeconds / detectionIntervalSeconds) {
          proctorWarning.current = false;
          faceDetectionHandler();
          consecutiveNoFaceCount = 0;
        }
      }
      if (resizedDetections?.length === 1) {
        proctorWarning.current = true;
        consecutiveNoFaceCount = 0;
        consecutiveMultFaceCount = 0;
        setIsCamera(null);
      }
      if (resizedDetections?.length > 1 && proctorWarning.current) {
        consecutiveMultFaceCount++;
        if (consecutiveMultFaceCount >= multipleFacesDetectionSeconds / detectionIntervalSeconds) {
          proctorWarning.current = false;
          multipleFaceDetectionHandler();
          consecutiveMultFaceCount = 0;
        }
      }
      canvas &&
        canvas?.width > 0 &&
        canvas?.height > 0 &&
        canvas?.getContext('2d')?.clearRect(0, 0, canvas?.width, canvas?.height);
      // canvas && faceapi?.draw?.drawDetections(canvas, resizedDetections);
    }, detectionIntervalSeconds);
  };

  const createCanvas = () => {
    if (document.getElementsByTagName('canvas').length === 0 && faceapi) {
      canvas = faceapi?.createCanvas(webcamBox);
      document.getElementById('proctorContainerIdheader')?.append(canvas);
      faceapi?.matchDimensions(canvas, {
        width: divMove?.clientWidth,
        height: divMove?.clientHeight,
      });
    }
  };

  const dragElement = (elmnt: any) => {
    let pos1 = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;
    const element: any = elmnt;
    const temp = document.getElementById(element?.id + 'header');

    const dragMouseDown = (e: any) => {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    };

    const elementDrag = (e: any) => {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      // set the element's new position:
      if (element.offsetTop - pos2 > 60 && element.offsetTop - pos2 < 460) {
        element.style.top = element.offsetTop - pos2 + 'px';
      }
      if (element.offsetLeft - pos1 > 0 && element.offsetLeft - pos1 < 1140) {
        element.style.left = element.offsetLeft - pos1 + 'px';
      }
    };

    const closeDragElement = () => {
      /* stop moving when mouse button is released:*/
      document.onmouseup = null;
      document.onmousemove = null;
    };

    if (temp) {
      /* if present, the header is where you move the DIV from:*/
      temp.onmousedown = dragMouseDown;
    } else {
      /* otherwise, move the DIV from anywhere inside the DIV:*/
      if (element) {
        element.onmousedown = dragMouseDown;
      }
    }
  };

  const cameraDetector = async () => {
    const details = await navigator.mediaDevices.enumerateDevices();
    const detailsData = details.find((obj: any) => obj.kind === 'videoinput');
    if (!detailsData?.deviceId || !detailsData?.groupId) {
      !isCamera && setIsCamera(ProctorStatus.Camera);
      cameraStart.current = true;
    } else {
      if (cameraStart.current) {
        window.location.reload();
      }
    }
  };

  const loadModels = async () => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(modelUrl),
      faceapi.nets.faceLandmark68TinyNet.loadFromUri(modelUrl),
      faceapi.nets.faceExpressionNet.loadFromUri(modelUrl),
      faceapi.nets.ageGenderNet.loadFromUri(modelUrl),
    ]).then(() => {
      createCanvas();
      startDetection();
    });
  };

  useEffect(() => {
    const timeover = setInterval(() => {
      startDetection();
    }, 5000);
    return () => clearInterval(timeover);
  }, []);

  useEffect(() => {
    const timeover = setTimeout(() => {
      webcamRef.current && loadModels();
    }, 2000);
    return () => clearTimeout(timeover);
  }, [webcamRef.current]);

  useEffect(() => {
    dragElement(divMove);
  }, [divMove]);

  useEffect(() => {
    const timeover = setInterval(() => {
      cameraDetector();
    }, 1500);
    return () => clearInterval(timeover);
  }, []);

  useEffect(() => {
    countTimer && countTimer >= 0 && countTimer < totalTimeCount && faceDetectionHandler();
  }, [countTimer]);

  useEffect(() => {
    multipleFaceTimer &&
      multipleFaceTimer >= 0 &&
      multipleFaceTimer < totalTimeCount &&
      multipleFaceDetectionHandler();
  }, [multipleFaceTimer]);

  return (
    <>
      <ProctorContainer $align={align === 'left'} id='proctorContainerId'>
        <DragBox id='proctorContainerIdheader'>
          <Webcam
            id='protcorWebcamId'
            audio={false}
            mirrored={true}
            height={140}
            width={200}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            videoConstraints={videoConstraints}
          />
        </DragBox>
      </ProctorContainer>
        <CameraDialog
        capture={capture}
        isModal={isCamera}

      />

    </>
  );
};

export default Proctor;
