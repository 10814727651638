import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import CandidateRoutes from './components/candidateRoutes';
import './css/candidate.css';
import styled from 'styled-components';
import { createTheme, ThemeProvider } from '@mui/material';
import ZecodeTheme from './zecodeTheme';
import { ThemeContext } from './components/context/themeContext';
import { DialogProvider } from './components/context/dialogContext';

const AppContainer = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const App = () => {
  const themeColorContext = useContext(ThemeContext);
  const theme = createTheme(ZecodeTheme, {
    // Custom colors created with augmentColor go here
    palette: {
      primary: ZecodeTheme.palette.augmentColor({
        color: {
          main: themeColorContext.themeColor || ZecodeTheme.palette.primary.main,
        },
      }),
    },
  });
  return (
    <AppContainer>
      <div className='light'>
        <ThemeProvider theme={theme}>
          <DialogProvider>
            <Router>
              <CandidateRoutes />
            </Router>
          </DialogProvider>
        </ThemeProvider>
      </div>
    </AppContainer>
  );
};

export default App;
