import React from 'react';
import FlipNumbers from 'react-flip-numbers';

export interface DigitProps {
  digit?: number;
  unit?: string;
}

const Digit = (props: DigitProps) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '0 5px',
          fontSize: '12px',
        }}
      >
        <div
          style={{
            backgroundColor: 'var(--first-color)',
            borderRadius: 8,
            padding: '2px 3px',
            marginBottom: 5,
          }}
        >
          <FlipNumbers
            color='var(--button-text-color)'
            play
            background='var(--first-color)'
            width={12}
            height={18}
            numbers={props.digit! <= 9 ? `0${props.digit}` : `${props.digit}`}
          />
        </div>
        {props.unit}
      </div>
    </div>
  );
};
export default Digit;
