import axios from 'axios';
import { baseUrl, linkExpiryUrl } from '../static/constants';

const client = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

client.interceptors.request.use((config) => {
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.error(error);
    if (error?.response?.status === 404 && error?.response?.data?.message === 'Link Expired') {
      window.location.href = `${linkExpiryUrl}`;
    }
  },
);

export { client };
