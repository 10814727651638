import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SxProps, Theme as MUITheme } from '@mui/material';
import { CSSProperties } from 'react';
import { colors } from '../../static/constants';

const BackgroundImage = '/images/interviewsBackground.svg';

export const useStylesCommon = makeStyles((theme: Theme) =>
  createStyles({
    formStyle: {
      background: '#EBEBEB',
      boxShadow: '0px 7px 16px rgba(0, 0, 0, 0.08)',
      borderRadius: '10px',
      textAlign: 'left',
      padding: '1.7vw 2.5vw',
      width: '42.9vw',
      display: 'flex;',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    myVideo: {
      width: '78px',
      height: '60px',
      position: 'absolute',
      top: '9%',
      left: '1%',
      zIndex: 7,
      borderRadius: '10px',
      border: '2px solid var(--first-color)',
    },
    userVideoDiv: {
      width: '225px',
      maxWidth: '600px',
      maxHeight: '450px',
      overflow: 'hidden',
    },
    userVideo: {
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      border: '2px solid var(--first-color)',
      zIndex: 20,
    },
    draggingCursor: {
      cursor: 'all-scroll',
    },
  }),
);

export const fullScreenStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  bottom: 5,
  flexDirection: 'column',
  position: 'fixed',
  zIndex: 15,
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.14)',
  height: '100vh',
  borderRadius: '10px',
};

export const nonFullScreenStyle = (isVideo: boolean, isFullScreen: boolean): SxProps => {
  const style: SxProps = {
    position: 'absolute',
    bottom: 10,
    zIndex: 100,
    right: isFullScreen ? 0 : undefined,
  };
  if (!isFullScreen) {
    style.left = isVideo ? 25 : 260;
  }
  return style;
};

export const FooterStyles: SxProps = {
  display: 'flex',
  padding: '0vh 4vw 0vh 2vw',
  flexDirection: 'row',
  justifyContent: 'space-between',
  fontFamily: 'var(--bodyText-font-family)',
  lineHeight: '21px',
  fontSize: '14px',
  fontWeight: '400',
};

export const InterviewTextStyles: SxProps = {
  marginBottom: '20px',
  fontFamily: 'var(--bodyText-font-family)',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '50px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textTransform: 'capitalize',
  color: colors.white,
  whiteSpace: 'pre',
};

export const StyledContainerStyles: SxProps = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '30px',
  backgroundImage: `url(${BackgroundImage})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

export const LogoWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  marginRight: '80px',
  marginTop: '20px',
  marginLeft: '38px',
};

export const ImageStyles: CSSProperties = {
  width: '65px',
  height: '65px',
  borderRadius: '40px',
};

export const micAndVideoButtonSx: (status: string) => SxProps = (status) => ({
  cursor: 'pointer',
  width: '40px !important',
  height: '40px !important',
  padding: '7px',
  borderRadius: '50%',
  color: 'white',
  background: status === 'off' ? colors.ferrariRed : '',
  border: status === 'on' ? `1px solid ${colors.white}` : 'none',
  '&:hover': {
    background: status === 'off' ? colors.brickRed : colors.gray,
    color: status === 'off' ? colors.white : colors.black,
  },
});

export const userVideoSx: SxProps = {
  width: '100%',
  height: '100%',
  borderRadius: '20px',
  border: '2px solid var(--first-color)',
  zIndex: 20,
};

export const micAndVideoOnPopupSx: SxProps = {
  cursor: 'pointer',
  width: '40px !important',
  height: '40px !important',
  padding: '7px',
  borderRadius: '50%',
  border: `1px solid ${colors.black}`,
  '&:hover': {
    background: colors.gray88,
  },
};

export const doubleArrowSx: SxProps = {
  width: '24px !important',
  height: '20px !important',
  color: 'var(--first-color)',
};

export const myVideoSx: SxProps = {
  width: '78px',
  height: '60px',
  position: 'absolute',
  top: '9%',
  left: '1%',
  zIndex: 7,
  borderRadius: '10px',
  border: '2px solid var(--first-color)',
  cursor: 'all-scroll',
};

export const combineSx = (...sxs: SxProps<MUITheme>[]): SxProps<MUITheme> => {
  return sxs.map((sx) => (Array.isArray(sx) ? sx : [sx])).flat();
};
