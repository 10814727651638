import React, { PropsWithChildren } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  Typography,
  Theme,
} from '@mui/material';
import { colors } from '../../static/constants';

export type DialogProps = {
  title: string;
  cancelButton?: (index?: number) => void;
  actionButton?: () => void;
  actionButtonTitle?: string;
  closeDialog?: () => void;
  hideActionButtons?: boolean;
  cancelButtonTitle?: string;
  disable?:boolean;
};

export default function ZecodeDialog({
  title,
  cancelButton,
  actionButton,
  actionButtonTitle = 'Save',
  children,
  closeDialog,
  hideActionButtons = false,
  cancelButtonTitle = 'Cancel',
  disable=false,
}: PropsWithChildren<DialogProps>) {

  const onClickSaveBtn = () => {
    actionButton?.();
    closeDialog?.();
  };
  return (
    <Dialog
      open
      onClose={() => closeDialog?.()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={dialogStyles}
    >
      <DialogTitle sx={dialogTitleStyles}>
        <Typography variant='h4'>{title}</Typography>
      </DialogTitle>
      <DialogContent sx={dialogContentStyles}>{children}</DialogContent>
      {!hideActionButtons ? (
        <DialogActions
          id='zecode-dialog-action'
          sx={{ gap: 2, p: (theme) => theme.spacing(1, 2, 3, 2) }}
        >
          {cancelButton && <Button
            size='small'
            variant='outlined'
            onClick={() => {
              cancelButton && cancelButton();
            }}
          >
            {cancelButtonTitle}
          </Button>}
          {actionButton && <Button size='small' variant='contained' onClick={onClickSaveBtn} disabled={disable}>
            {actionButtonTitle}
          </Button>}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}

const dialogStyles: SxProps<Theme> = {
  p: (theme) => theme.spacing(1, 2, 1, 2),
  '.MuiDialog-paper': {
    borderRadius: '4px',
    minWidth: 400,
    maxWidth: 500,
  },
};

const dialogContentStyles: SxProps<Theme> = {
  '.MuiDialogContent-root&.MuiDialogContent-root': {
    p: (theme) => theme.spacing(3, 2, 3, 2),
  },
};

const dialogTitleStyles: SxProps<Theme> = {
  background: colors.indigoBlue,
  color: colors.white,
  padding: (theme) => theme.spacing(1, 2, 1, 2),
};
