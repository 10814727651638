import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Digit from './Digit';
import { useNavigate, useParams } from 'react-router-dom';
import {
  RouterNames,
  SECTIONS,
  StorageKeys,
  examSubmitted,
  leavingTabsCount,
} from '../../static/constants';
import { updateAssessmentStatus } from '../../api/requests/candidate';
import { removeItem } from '../../util/storage';

const CountDown = ({
  updateResponses,
  timeEnd,
  totalTime,
  markedForReviewQuestions,
  testCasesExecute,
  codeExecute,
}: {
  codeExecute?: any;
  updateResponses?: any;
  testCasesExecute?: any;
  timeEnd: any;
  totalTime: number;
  markedForReviewQuestions: string[];
}) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const today = moment();

  const deadline = moment(timeEnd, 'YYYY-MM-DD hh:mm:ss', true);

  const milliseconds = deadline.diff(moment(), 'milliseconds');
  const hours = moment.duration(milliseconds).hours();
  const minutes = moment.duration(milliseconds).minutes();
  const seconds = moment.duration(milliseconds).seconds();
  const [time, setTime] = useState([hours, minutes, seconds]);

  useEffect(() => {
    setTime([hours, minutes, seconds]);
    const timer = setInterval(() => {
      const milliseconds = deadline.diff(moment(), 'milliseconds');
      const hours = moment.duration(milliseconds).hours();
      const minutes = moment.duration(milliseconds).minutes();
      const seconds = moment.duration(milliseconds).seconds();
      setTime([hours, minutes, seconds]);
    }, 1000);
    return () => clearInterval(timer);
  }, [timeEnd, totalTime]);

  const updateStatus = async () => {
    const params = {
      timeSpent: totalTime,
      markedForReview: markedForReviewQuestions,
      timeUp: true,
    };
    await updateAssessmentStatus(params, id!);
  };

  const updateCandidateResponse = async () => {
    if (updateResponses) {
      await updateResponses();
    }
  };

  const updateTestCaseResponse = async () => {
    await testCasesExecute();
  };

  const updateDatabasesResponse = async () => {
    const section = localStorage.getItem('section');
    if (section === SECTIONS.databaseQuestions) {
      codeExecute();
    }
  };

  if (deadline.isBefore(today)) {
    updateStatus();
    removeItem(StorageKeys.WarningCount + id);
    removeItem(StorageKeys.ProctorStatus + id);
    removeItem(leavingTabsCount);
    removeItem(StorageKeys.candidateName);
    updateCandidateResponse();
    updateTestCaseResponse();
    updateDatabasesResponse();
    navigate(`${RouterNames.assessmentCompleted}/${id}?${examSubmitted}`);
  }

  return (
    <div style={{ display: 'flex', marginRight: '-32px', marginTop: '7px' }}>
      <Digit digit={time[0] ? time[0] : 0} unit='Hours' />:
      <Digit digit={time[1] ? time[1] : 0} unit='Minutes' />:
      <Digit digit={time[2] ? time[2] : 0} unit='Seconds' />
    </div>
  );
};

export default CountDown;
