import React, { ReactNode, createContext, useState } from 'react';
import ZecodeDialog, { DialogProps } from '../common/zecodeDialog';

type ShowDialogProps = DialogProps & {
  content?: (closeDialog: () => void) => ReactNode;
};

type DialogContexts = {
  showDialog: (dialog: ShowDialogProps) => void;
};

type DialogContextProvider = {
  children: ReactNode;
};

// Create a new context for the Dialog
export const DialogContext = createContext<DialogContexts>({
  showDialog: () => null,
});

export const DialogProvider: React.FC<DialogContextProvider> = ({ children }) => {
  const [dialogs, setDialogs] = useState<ShowDialogProps[]>([]);
  // Function to hide an dialog based on its index
  const hideDialogBox = (index: number) => {
    setDialogs((prev) => prev.filter((_, i) => i !== index));
  };

  // Context value containing the showDialog function
  const contextValue: DialogContexts = {
    showDialog: (dialog: ShowDialogProps) => {
      const dialogContent: ShowDialogProps = {
        ...dialog,
      };
      setDialogs((prev) => [...prev, dialogContent]);
    },
  };

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
      {dialogs.map((dialog, index) => (
        <ZecodeDialog
          title={dialog.title}
          actionButton={dialog.actionButton}
          actionButtonTitle={dialog.actionButtonTitle}
          cancelButton={() => hideDialogBox(index)}
          cancelButtonTitle={dialog.cancelButtonTitle}
          closeDialog={() => hideDialogBox(index)}
          hideActionButtons={dialog.hideActionButtons}
          key={index}
        >
          {dialog?.content?.(() => hideDialogBox(index))}
        </ZecodeDialog>
      ))}
    </DialogContext.Provider>
  );
};
