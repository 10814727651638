import React from 'react';
import { Box } from '@mui/material';

const LoadingPage = () => {
  const fontFamily = 'var(--bodyText-font-family)';
  const textBoxSx = {
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    marginTop: '90px',
    fontFamily,
  };
  const contentBoxSX = {
    display: 'flex',
    flexDirection: 'column',
    flex: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily,
  };

  return (
    <Box sx={contentBoxSX}>
      <Box sx={textBoxSx}>Loading ...</Box>
    </Box>
  );
};

export default LoadingPage;
