import React from 'react';
import { Box, List, ListItemText, SxProps, Typography } from '@mui/material';
import { InterviewLanguages } from '../../static/constants';
import { IDEList } from '../candidates/interviewStyles';
import { ProjectSetupProps } from '../hooks/useGetAllProjectSetups';

export function DropdownByGroup({
  filterName,
  projectSetupList,
  title,
  activeQuestionIde,
  onSelectedOption,
}: {
  filterName: string;
  title: string;
  projectSetupList: ProjectSetupProps[];
  onSelectedOption: (value: number) => void;
  activeQuestionIde?: number;
}) {
  const filteredProjectList = projectSetupList
    ?.filter((project) => project?.type === filterName && !project.isHidden)
    .filter(Boolean);
  if (!filteredProjectList.length) {
    return null;
  }
  const isProjectListGreatorThanSeven = filteredProjectList.length > 7;
  const firstColumnList = isProjectListGreatorThanSeven
    ? filteredProjectList.slice(0, Math.ceil(filteredProjectList.length / 2))
    : filteredProjectList;
  const secondColumnList = isProjectListGreatorThanSeven
    ? Math.ceil(filteredProjectList.length / 2)
    : 0;

  return (
    <div>
      <Typography sx={{ color: 'var(--first-color)' }} variant='h5'>
        {title}
      </Typography>
      <Box sx={mainContainerStyles}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {firstColumnList.map((filteredProject, index) => {
              const selectedIdeLogo = InterviewLanguages.find(
                (interview) => interview.id === filteredProject?.domainID,
              )?.image;
              return (
                <List key={index}>
                  <IDEList
                    $active={activeQuestionIde === filteredProject?.domainID}
                    onClick={() => onSelectedOption(filteredProject.domainID)}
                    key={index}
                  >
                    {selectedIdeLogo && (
                      <img
                        src={selectedIdeLogo}
                        style={{ aspectRatio: 3 / 2 }}
                        height={16}
                        width={16}
                        alt='Icon'
                      />
                    )}

                    <ListItemText primary={filteredProject?.setup.title} />
                  </IDEList>
                </List>
              );
            })}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {isProjectListGreatorThanSeven &&
              filteredProjectList.slice(secondColumnList).map((filteredProject, index) => {
                const selectedIdeLogo = InterviewLanguages.find(
                  (interview) => interview.id === filteredProject?.domainID,
                )?.image;
                return (
                  <List key={index}>
                    <IDEList
                      $active={activeQuestionIde === filteredProject?.domainID}
                      onClick={() => onSelectedOption(filteredProject.domainID)}
                      key={index}
                    >
                      {selectedIdeLogo && (
                        <img
                          src={selectedIdeLogo}
                          style={{ aspectRatio: 3 / 2 }}
                          height={16}
                          width={16}
                          alt='Icon'
                        />
                      )}
                      <ListItemText primary={filteredProject?.setup.title} />
                    </IDEList>
                  </List>
                );
              })}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

const mainContainerStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  '&& ul': {
    borderLeft: '0.75px solid #1e1b31',
    padding: '5px 20px 0px 0px',
  },
  '&&li': {
    width: '100px',
  },
  '&& ul li': {
    listStyle: 'none',
    fontWeight: 400,
    cursor: 'pointer',
    padding: '0px 5px',
    borderRadius: '2px',
  },
  '&& ul:first-child': {
    border: 'none',
  },
};
