import { RouterNames } from '../../static/constants';
import { useLocation } from 'react-router-dom';

const useDisableInspect = () => {
  const reactAppPlatfrom = process.env.REACT_APP_PLATFORM;
  const location = useLocation();

  const handleContextMenu = (e: any) => {
    e.preventDefault();
  };

  const handleDisable = () => {
    if (
      (location.pathname.includes(RouterNames.assessment) ||
        location.pathname.includes(RouterNames.assessmentDetails)) &&
      reactAppPlatfrom === 'staging'
    ) {
      document.addEventListener('contextmenu', handleContextMenu);
      document.addEventListener(
        'keydown',
        (event: any) =>
          (event.keyCode === 123 ||
            (event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
            (event.ctrlKey && event.shiftKey && event.keyCode === 74) ||
            (event.ctrlKey && event.shiftKey && event.keyCode === 67) ||
            (event.ctrlKey && event.keyCode === 85)) &&
          handleContextMenu(event),
      );
    }
  };

  return handleDisable;
};

export default useDisableInspect;
