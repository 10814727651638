import React from 'react';
import { Box, DialogContentText, Typography } from '@mui/material';
import ZecodeCandidateDialogContent from './zecodeCandidateDialogContent';
import { colors } from '../../static/constants';
import { commonStrings } from '../../static/commonStrings';

const AssessmentDialogContent = ({
  keyValue,
  closeDialog,
  onClickYesEvent,
  popupIsActiveMessage,
  dialogText,
  value,
}: {
  keyValue?: string;
  closeDialog: () => void;
  onClickYesEvent: (dialogClose: () => void, value?: string) => void;
  popupIsActiveMessage: string;
  dialogText: string;
  value?: string;
}) => {
  return (
    <Box sx={{ minWidth: 350 }}>
      <ZecodeCandidateDialogContent
        content={
          <DialogContentText sx={{ display: 'flex', gap: 3, flexDirection: 'column' }}>
            <Typography variant='h5' sx={{ color: colors.midnightIndigo }}>
              {popupIsActiveMessage}
            </Typography>
            <Typography variant='bodyMediumStrong' sx={{ color: colors.manatee }}>
              {dialogText}
            </Typography>
          </DialogContentText>
        }
        leftButton={{
          text: keyValue === 'submit' ? commonStrings.popUp.buttonContent.cancel : 'No',
          action: () => closeDialog(),
        }}
        rightButton={{
          text:
            keyValue === 'submit' || keyValue === 'timeCompletion'
              ? commonStrings.popUp.buttonContent.submit
              : 'Yes',
          action: () => onClickYesEvent(closeDialog, value!),
        }}
        showLeftButton={!(keyValue === 'timeCompletion')}
      />
    </Box>
  );
};

export default AssessmentDialogContent;
