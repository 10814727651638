import { ProjectSetupProps } from '../components/hooks/useGetAllProjectSetups';
import { ThemeConfigurationType } from '../components/Types/Index';

export const setProfileDetailSettings = (profileDetails: ThemeConfigurationType) => {
  document.documentElement.style.setProperty('--first-color', profileDetails.firstColor!);
  document.documentElement.style.setProperty('--bodyText-color', profileDetails.bodyTextColor!);

  document.documentElement.style.setProperty(
    '--headLineText-color',
    profileDetails.headLineTextColor!,
  );

  document.documentElement.style.setProperty(
    '--headLineTextInMail-color',
    profileDetails.headLineTextColorInMail!,
  );

  document.documentElement.style.setProperty('--header-font-family', profileDetails.headLineText!);

  document.documentElement.style.setProperty('--bodyText-font-family', profileDetails.bodyText!);

  document.documentElement.style.setProperty(
    '--button-text-color',
    profileDetails.buttonTextColor!,
  );
};

export const generateKey = () => (Math.floor(Math.random() * 90000) + 10000).toString();

export const getIdeType = ({
  selectedIde,
  allProjectSetups,
}: {
  selectedIde: number;
  allProjectSetups: ProjectSetupProps[];
}) => {
  return allProjectSetups?.filter(
    (projectSetupList) => projectSetupList.domainID === selectedIde,
  )[0]?.type;
};
