/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactNode } from 'react';

import { SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import { combineSx } from './styles';

type button = {
  text?: string;
  action?: (event: React.SyntheticEvent) => void;
};

// Pass in null for rightButton or leftButton to avoid showing corresponding button
interface ZecodeDialogContentProps {
  content?: ReactNode;
  isDeleteAction?: boolean;
  isError?: boolean;
  leftButton?: button;
  // We may not need leftButton sometimes
  showLeftButton?: boolean;
  onClose?: (event: React.SyntheticEvent) => void;
  rightButton?: button;
  text?: string;
  textSxProps?: SxProps;
  showRightButton?: boolean;
}

/* Standard dialog to display an alert consisting of a title, body text and the option to display two buttons with separate callbacks.
Dialog will close on either button click. */
export function ZecodeCandidateDialogContent({
  isDeleteAction = false,
  isError = false,
  text,
  textSxProps,
  content,
  onClose = () => {},
  leftButton = { text: 'No', action: () => {} },
  rightButton = { text: 'Yes', action: () => {} },
  showLeftButton = true,
  showRightButton = true,
}: ZecodeDialogContentProps) {
  return (
    <>
      {text && (
        <DialogContentText sx={combineSx(textStyles, textSxProps || {})}>{text}</DialogContentText>
      )}
      {content}
      <DialogActions
        id='zecode-dialog-content-action'
        onClick={onClose}
        sx={{ gap: 2, padding: (theme) => theme.spacing(3, 0, 1, 0) }}
      >
        {showLeftButton && leftButton && (
          <Button onClick={leftButton.action} variant='outlined' size='medium'>
            {leftButton.text || 'No'}
          </Button>
        )}
        {showRightButton && rightButton && (
          <Button size='medium' variant='contained' onClick={rightButton.action} disabled={isError}>
            {rightButton.text || 'Yes'}
          </Button>
        )}
      </DialogActions>
    </>
  );
}

const textStyles: SxProps = {
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  hyphens: 'auto',
  whiteSpace: 'pre-line',
};

export default ZecodeCandidateDialogContent;
