import { Loader } from 'esbuild-wasm';

const McqIcon = '/images/mcq.svg';
const CodingIcon = '/images/Coding.svg';
const OpenEndIcon = '/images/openEnd.svg';
const DatabaseIcon = '/images/databasequestions_Icon.svg';
const ComprehensionIcon = '/images/ComprehensionIcon.svg';
const CIcon = '/images/cIcon.svg';
const CSharpIcon = '/images/cSharpIcon.svg';
const CPlusIcon = '/images/cPlusIcon.svg';
const PhpIcon = '/images/php.svg';
const PythonIcon = '/images/python.svg';
const JavaIcon = '/images/java.svg';
const GoIcon = '/images/goIcon.svg';
const BashIcon = '/images/bashIcon.svg';
const MySQLIcon = '/images/mySQL.svg';
const SwiftIcon = '/images/swiftIcon.svg';
const KotlinIcon = '/images/kotlinIcon.svg';
const ReactLogo = '/images/reactIcon.svg';
const AngularLogo = '/images/angularIcon.svg';
const JavascriptLogo = '/images/javascriptIcon.svg';
const TypescriptLogo = '/images/typescriptIcon.svg';
const HTMlCSSLogo = '/images/htmlcssIcon.svg';
const JupyterLogo = '/images/JupyterLogo.svg';
const RustLogo = '/images/rustLogo.svg';
const RLogo = '/images/RLogo.svg';
const RubyLogo = '/images/RubyLogo.svg';
const PerlLogo = '/images/PerlLogo.svg';
const ElixirLogo = '/images/ElixirLogo.svg';
const WhiteboardLogo = '/images/excalidraw.svg';
const MongodbLogo = '/images/mongodbLogo.svg';
const DartLogo = '/images/DartLogo.svg';

export const zecodeLogo =
  'https://zecode-product.s3.ap-south-1.amazonaws.com/EnterpriseProfileImages/zecodeIcon.png';

export const AsciiValue = 65;

export const zecodeGrey = '#ebebeb';

export const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const modelUrl = process.env.REACT_APP_MODEL_URL;

export const landingPageUrl = process.env.REACT_APP_ZECODE_LANDING_PAGE;

export const contactUsUrl = process.env.REACT_APP_ZECODE_CONTACT_US;

export const privacyPolicyUrl = process.env.REACT_APP_ZECODE_PRIVACY_POLICY;

export const termsAndServices = process.env.REACT_APP_ZECODE_TERMS_OF_SERVICE;

export const snapshotTimeInterval = process.env.REACT_APP_SNAPSHOT_TIME_INTERVAL;

export const assessmentIdForNewCandidate = process.env.REACT_APP_ASSESSMENT_ID;

export const linkExpiryUrl = '/';

export const compilerTypes = {
  general: 'General',
  frontend: 'Front End',
  databases: 'Databases',
};

export const InterviewLanguages: InterviewLanguageProps[] = [
  {
    id: 1,
    value: 'react',
    data: 'React',
    image: ReactLogo,
    type: compilerTypes.frontend,
  },
  {
    id: 2,
    value: 'vanilla',
    data: 'Javascript',
    image: JavascriptLogo,
    type: compilerTypes.frontend,
  },
  {
    id: 3,
    value: 'angular',
    data: 'Angular',
    image: AngularLogo,
    type: compilerTypes.frontend,
  },
  {
    id: 4,
    value: 'react-ts',
    data: 'Typescript',
    image: TypescriptLogo,
    type: compilerTypes.frontend,
  },
  {
    id: 5,
    value: 'php',
    data: 'PHP',
    image: PhpIcon,
    type: compilerTypes.general,
  },
  // { id: 6, value: 'python2', data: 'Python', image: PythonIcon },
  { id: 7, value: 'c', data: 'C', image: CIcon, type: compilerTypes.general },
  {
    id: 8,
    value: 'cpp',
    data: 'C++',
    image: CPlusIcon,
    type: compilerTypes.general,
  },
  {
    id: 9,
    value: 'csharp',
    data: 'C#',
    image: CSharpIcon,
    type: compilerTypes.general,
  },
  {
    id: 10,
    value: 'java',
    data: 'Java',
    image: JavaIcon,
    type: compilerTypes.general,
  },
  {
    id: 11,
    value: 'Go',
    data: 'Go',
    image: GoIcon,
    type: compilerTypes.general,
  },
  {
    id: 12,
    value: 'bash',
    data: 'Bash',
    image: BashIcon,
    type: compilerTypes.general,
  },
  {
    id: 13,
    value: 'sql',
    data: 'Sql',
    image: MySQLIcon,
    type: compilerTypes.databases,
  },
  {
    id: 14,
    value: 'swift',
    data: 'Swift',
    image: SwiftIcon,
    type: compilerTypes.general,
  },
  {
    id: 15,
    value: 'kotlin',
    data: 'Kotlin',
    image: KotlinIcon,
    type: compilerTypes.general,
  },
  {
    id: 16,
    value: 'python',
    data: 'Python',
    image: PythonIcon,
    type: compilerTypes.general,
  },
  {
    id: 17,
    value: 'static',
    data: 'HTML/CSS',
    image: HTMlCSSLogo,
    type: compilerTypes.frontend,
  },
  // { id: 18, value: 'jupyter', data: 'Jupyter', image: JupyterLogo },
  {
    id: 19,
    value: 'rust',
    data: 'Rust',
    image: RustLogo,
    type: compilerTypes.general,
  },
  { id: 20, value: 'R', data: 'R', image: RLogo, type: compilerTypes.general },
  {
    id: 21,
    value: 'ruby',
    data: 'Ruby',
    image: RubyLogo,
    type: compilerTypes.general,
  },
  {
    id: 22,
    value: 'perl',
    data: 'Perl',
    image: PerlLogo,
    type: compilerTypes.general,
  },
  {
    id: 23,
    value: 'elixir',
    data: 'Elixir',
    image: ElixirLogo,
    type: compilerTypes.general,
  },
  {
    id: 24,
    value: 'dart',
    data: 'Dart',
    image: DartLogo,
    type: compilerTypes.general,
  },
  {
    id: 25,
    value: 'mongodb',
    data: 'MongoDB',
    image: MongodbLogo,
    type: compilerTypes.databases,
  },
  {
    id: 18,
    value: 'reactnative',
    data: 'React Native',
    image: ReactLogo,
    type: compilerTypes.frontend,
  },
  {
    id: 26,
    value: 'whiteBoard',
    data: 'WhiteBoard',
    image: WhiteboardLogo,
    type: compilerTypes.databases,
  },
];

export interface InterviewLanguageProps {
  id: number;
  value: string;
  data: string;
  image: string;
  type: string;
}

export type ListOfLanguages = InterviewLanguageProps[][];

export const Languages = [
  { id: 1, value: 'react', data: 'React', image: ReactLogo },
  { id: 2, value: 'vanilla', data: 'Javascript', image: JavascriptLogo },
  { id: 3, value: 'angular', data: 'Angular', image: AngularLogo },
  { id: 4, value: 'react-ts', data: 'Typescript', image: TypescriptLogo },
  { id: 5, value: 'php', data: 'PHP', image: PhpIcon },
  { id: 6, value: 'python2', data: 'Python2', image: PythonIcon },
  { id: 7, value: 'c', data: 'C', image: CIcon },
  { id: 8, value: 'cpp', data: 'C++', image: CPlusIcon },
  { id: 9, value: 'csharp', data: 'C#', image: CSharpIcon },
  { id: 10, value: 'java', data: 'Java', image: JavaIcon },
  { id: 11, value: 'Go', data: 'Go', image: GoIcon },
  { id: 12, value: 'python3', data: 'Python', image: PythonIcon },
  { id: 13, value: 'sql', data: 'Sql', image: MySQLIcon },
  { id: 14, value: 'swift', data: 'Swift', image: SwiftIcon },
  { id: 15, value: 'kotlin', data: 'Kotlin', image: KotlinIcon },
  { id: 16, value: 'django', data: 'Django', image: PhpIcon },
  { id: 17, value: 'static', data: 'HTML/CSS', image: HTMlCSSLogo },
  { id: 18, value: 'reactnative', data: 'React Native', image: ReactLogo },
] as const;

export const MAX_SCORE = 10;

export const QuestionStatus = {
  Answered: 'Answered',
  review: 'Review',
  Unanswered: 'Unanswered',
};

export const AvailableModules = {
  assessments: 'Assessments',
  interviews: 'Interviews',
  proctoring: 'Proctoring',
  whiteLabelling: 'White Labelling',
  questionPool: 'Question Pool',
};

export const QuestionType = {
  Easy: '#1ba94c',
  Hard: '#d11534',
  Medium: '#db7100',
};

export const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 500,
    },
    items: 5,
    slidesToSlide: 5,
  },
};

export const SECTIONS = {
  codingChallenges: 'codingChallenges',
  multipleChoice: 'multipleChoice',
  openEnd: 'openEnd',
  databaseQuestions: 'databaseQuestions',
  comprehension: 'comprehension',
} as const;

export const SectionDetails = [
  {
    Icon: McqIcon,
    key: SECTIONS.multipleChoice,
    questionDetails: 'MultipleChoiceQuestions',
    section: 'Multiple Choice Questions',
    sectionName: 'Multiple choice questions',
    time: 10,
  },
  {
    Icon: CodingIcon,
    key: SECTIONS.codingChallenges,
    questionDetails: 'CodingChallengeQuestions',
    section: 'Coding Questions',
    sectionName: 'Coding questions',
    time: 25,
  },
  {
    Icon: OpenEndIcon,
    key: SECTIONS.openEnd,
    questionDetails: 'Comprehensions Questions',
    section: 'Open-ended Questions',
    sectionName: 'Open-ended questions',
    time: 15,
  },
  {
    Icon: ComprehensionIcon,
    key: SECTIONS.comprehension,
    questionDetails: '',
    section: 'Comprehension Questions',
    sectionName: 'Comprehension questions',
    time: 10,
  },
  {
    Icon: DatabaseIcon,
    key: SECTIONS.databaseQuestions,
    questionDetails: '',
    section: 'Database Questions',
    sectionName: 'Database questions',
    time: 15,
  },
] as const;

export const RouterNames = {
  assessment: '/assessment',
  assessmentCompleted: '/assessmentCompleted',
  interviewCompleted: '/interviewCompleted',
  leaveInterview: '/leaveInterview',
  assessmentDetails: '/assessmentDetails',
  candidateInvitation: '/',
  coding: '/coding',
  help: '/help',
  interview: '/interviewCoding',
  interviewDetails: '/interviewDetails',
  newCandidate: '/newCandidate',
} as const;

export const sectionStatus = {
  complete: 'Complete',
  inComplete: 'Incomplete',
  inProgress: 'In progress',
  notStarted: 'Not Started',
  submitted: 'Submitted',
  timeExpired: 'Time expired',
  invited: 'Invited',
  noShow: 'No show',
} as const;

export const endInterviewText = 'End Interview';

export const TotalTime = 60;

export enum StackblitzDefaults {
  HEIGHT = '530',
  ID = 'stackblitz',
  OPEN_FILE = 'index.html',
}

export enum Status {
  backToAssessment = 'backToAssessment',
  changeIDE = 'changeIDE',
  interviewChangeIDE = 'interviewChangeIDE',
  interviewResetCode = 'interviewResetCode',
  interviewCompleted = 'interviewCompleted',
  interviewLeave = 'interviewLeave',
  submitInterviewFeedback = 'submitInterviewFeedback',
  resetCode = 'resetCode',
  submitAssessment = 'submitAssessment',
  submitCode = 'submitCode',
  timeCompleted = 'timeCompleted',
  deleteQuestion = 'deleteQuestion',
  endCall = 'endCall',
}

export enum Time {
  MILLI_SECOND = 1,
  SECOND = 1000,
  MINUTE = 60 * SECOND,
  HOUR = 60 * MINUTE,
  DAY = 24 * HOUR,
}

export const demoAssessmentId = '647098e7ad683c67c403d50d';

export const LoaderValues = (ide: number) => {
  let loader: Loader = 'js';
  let slectedIDE = '';
  if (ide === 4) {
    slectedIDE = 'TYPESCRIPT';
  }
  if (ide === 1) {
    slectedIDE = 'JSX';
  }
  switch (slectedIDE) {
    case 'TYPESCRIPT':
      loader = 'ts';
      break;
    case 'JSX':
      loader = 'jsx';
      break;
    default:
      break;
  }
  return loader;
};

export enum AssessmentType {
  Interview = 'Interview',
  Candidate = 'Candidate',
}

export enum ProctorStatus {
  Camera = 'Camera',
  NoFace = 'NoFace',
  MultipleFaces = 'MultipleFaces',
}

export enum StorageKeys {
  WarningCount = 'WarningCount',
  ProctorStatus = 'ProctorStatus',
  candidateName = 'candidateName',
}
export const showTableQuery = 'show tables;';
export const sqlObjectKey = 'Tables_in_ZESSTA';
export const datamodelsUrl = [
  {
    url: 'https://zecode-product.s3.ap-south-1.amazonaws.com/databaseModels/databaseModel1.png',
  },
];
export const mongoDBDatamodelsUrl = [
  {
    url: 'https://zecode-product.s3.ap-south-1.amazonaws.com/databaseModels/mongodbModel.png',
  },
];
export const noServerResponse = 'The server did not respond. Please try again later.';
export const noRowsFound = 'No records found';
export const leavingTabsCount = 'leavingTabsCount';

export const examSubmitted = 'examSubmitted=true';

export const guidelinesVariables = {
  clickHere: 'click Here',
  zecodeVideoText: 'Learn how Zecode works before you begin the assessment.',
  watchVideo: 'Watch Video',
};

export const secondsDelay = 60000;

export const multipleFacesDetectionSeconds = 15000;

export const noFaceDetectionSeconds = 20000;

export const detectionIntervalSeconds = 300;

export const warningsLeft = 15;

export const candidateStatus = {
  invited: 'INVITED',
  inProgress: 'IN_PROGRESS',
  submitted: 'SUBMITTED',
  notAttempted: 'NOT_ATTEMPTED',
  notStarted: 'NOT_STARTED',
};

export const colors = {
  silverGray: '#AAAAAA',
  darkGrey: '#636363',
  gray88: '#e0e0e0',
  white: '#ffffff',
  red: 'red',
  black: '#000000',
  testCasesPassed: '#28B894',
  testCasesFailed: '#FF4D6A',
  blue: '#4A90E2',
  grey: '#EBEBEB',
  green: '#11ac85',
  pictonBlue: '#4cb2e9',
  acceptGreen: '#2a7221',
  thickRed: '#ff0000',
  philippineGreen: '#0b8341',
  lightGreen: '#6eba6e',
  brickRed: '#ed2929',
  gray: '#808080',
  ferrariRed: '#ff2400',
  midnightIndigo: '#1B1633',
  manatee: '#94909E',
  indigoBlue: '#473f6e',
  Alabaster: '#f1f1f1',
  mintGreen: '#28b894',
  paleGray:'#d9d9d9'
};

export const IDE_TEMPLATES = {
  REACT: 'react',
  REACT_NATIVE: 'reactnative',
};

export const PROJECT_SETUP_TYPES = {
  FRONT_END: 'frontend',
  BACK_END: 'backend',
  DATABASE: 'database',
  DESIGN:'design',
};
export const TempLanguages: InterviewLanguageProps[] = [
  {
    id: 1,
    value: 'react',
    data: 'React',
    image: ReactLogo,
    type: compilerTypes.frontend,
  },
  {
    id: 2,
    value: 'vanilla',
    data: 'Javascript',
    image: JavascriptLogo,
    type: compilerTypes.frontend,
  },
  {
    id: 3,
    value: 'angular',
    data: 'Angular',
    image: AngularLogo,
    type: compilerTypes.frontend,
  },
  {
    id: 4,
    value: 'react-ts',
    data: 'Typescript',
    image: TypescriptLogo,
    type: compilerTypes.frontend,
  },
  {
    id: 5,
    value: 'php',
    data: 'PHP',
    image: PhpIcon,
    type: compilerTypes.general,
  },
  // { id: 6, value: 'python2', data: 'Python', image: PythonIcon },
  { id: 7, value: 'c', data: 'C', image: CIcon, type: compilerTypes.general },
  {
    id: 8,
    value: 'cpp',
    data: 'C++',
    image: CPlusIcon,
    type: compilerTypes.general,
  },
  {
    id: 9,
    value: 'csharp',
    data: 'C#',
    image: CSharpIcon,
    type: compilerTypes.general,
  },
  {
    id: 10,
    value: 'java',
    data: 'Java',
    image: JavaIcon,
    type: compilerTypes.general,
  },
  {
    id: 11,
    value: 'Go',
    data: 'Go',
    image: GoIcon,
    type: compilerTypes.general,
  },
  {
    id: 12,
    value: 'bash',
    data: 'Bash',
    image: BashIcon,
    type: compilerTypes.general,
  },
  {
    id: 13,
    value: 'sql',
    data: 'Sql',
    image: MySQLIcon,
    type: compilerTypes.databases,
  },
  {
    id: 14,
    value: 'swift',
    data: 'Swift',
    image: SwiftIcon,
    type: compilerTypes.general,
  },
  {
    id: 15,
    value: 'kotlin',
    data: 'Kotlin',
    image: KotlinIcon,
    type: compilerTypes.general,
  },
  {
    id: 16,
    value: 'python',
    data: 'Python',
    image: PythonIcon,
    type: compilerTypes.general,
  },
  {
    id: 17,
    value: 'static',
    data: 'HTML/CSS',
    image: HTMlCSSLogo,
    type: compilerTypes.frontend,
  },
  // { id: 18, value: 'jupyter', data: 'Jupyter', image: JupyterLogo },
  {
    id: 19,
    value: 'rust',
    data: 'Rust',
    image: RustLogo,
    type: compilerTypes.general,
  },
  { id: 20, value: 'R', data: 'R', image: RLogo, type: compilerTypes.general },
  {
    id: 21,
    value: 'ruby',
    data: 'Ruby',
    image: RubyLogo,
    type: compilerTypes.general,
  },
  {
    id: 22,
    value: 'perl',
    data: 'Perl',
    image: PerlLogo,
    type: compilerTypes.general,
  },
  {
    id: 23,
    value: 'elixir',
    data: 'Elixir',
    image: ElixirLogo,
    type: compilerTypes.general,
  },
  {
    id: 24,
    value: 'dart',
    data: 'Dart',
    image: DartLogo,
    type: compilerTypes.general,
  },
  {
    id: 25,
    value: 'mongodb',
    data: 'MongoDB',
    image: MongodbLogo,
    type: compilerTypes.databases,
  },
  {
    id: 18,
    value: 'reactnative',
    data: 'React Native',
    image: ReactLogo,
    type: compilerTypes.frontend,
  },
];

export const aceEditor = 'Ace Editor';
