export const year = new Date().getFullYear();
export const commonStrings = {
  assessment: {
    assessmentStartPage: {
      buttonText: 'Click here to proceed',
      candidateInvitationCardHeading: 'Hey ',
      caption: 'Evaluate and hire best candidates!',
      contactUs: 'Contact Us',
      descriptionNote: "You've been invited to take a test for the role of ",
      descriptionTextOne: 'We wish you...',
      descriptionTextTwo: 'All the very best!',
      helpText: 'Help',
      mailText: `© ${year} zecode.com`,
      name: 'Zessta Software Services Pvt. Ltd',
      note: ' Note: Clicking on proceed will not start your test; instead, you will be directed to the test overview screen, where you may begin your assessment',
      privacyPolicy: 'Privacy Policy',
      sections: [
        'You can start your test by selecting one of the sections from below and clicking the "Start Exam" button',
        'You can navigate across sections and questions during the assessment',
        'You have the flexibility to complete each section individually',
      ],
      sectionsHeading: 'Exam Sections',
      sectionsList: [
        {
          label: 'Multiple Choice Questions',
          value: 'multipleChoice',
          apikey: 'multipleChoiceQuestions',
        },
        {
          label: 'Coding Questions',
          value: 'codingChallenges',
          apikey: 'questions',
        },
        {
          label: 'Open-ended Questions',
          value: 'openEnd',
          apikey: 'openEndQuestions',
        },
        {
          label: 'Comprehension Questions',
          value: 'comprehension',
          apikey: 'comprehensionQuestions',
        },
        {
          label: 'Database Questions',
          value: 'databaseQuestions',
          apikey: 'databaseQuestions',
        },
      ],
      sectionsSubHeading: 'Select a category',
      startButtonText: 'Start Exam',
      termsAndConditions: 'Terms and conditions',
      testGuidelines: [
        '### Assessment Overview:',
        'Once the timer begins, you cannot stop or pause it to take a break, until the assessment ends.',
        'You can navigate across sections during assessment, and your progress will be auto saved.',
        'Assessment will be automatically submitted when the timer ends, and your auto saved progress, if any, will be evaluated.',
        'You can submit the assessment manually as well, by clicking the "Submit Exam" button, if you are done before the allocated test duration.',
        '### Test Environment Guidelines:',
        'Please ensure you are in a quiet and distraction free environment.',
        'Keep your surroundings well lit for clear visibility.',
        'This is a proctored assessment. Kindly ensure that system camera is well functioning.',
        'Ensure your internet connection is stable throughout the assessment.',
        'Use the latest, updated web browser for the best experience.',
        '### Code Submission:',
        'Each coding assignment is auto saved, however it is recommended to submit each of your code solutions by clicking the "Submit" button, in the coding assignment screen, so as to ensure no progress is lost.',
        '### Compliance Infractions:',
        'Please refrain from switching between browser tabs, and note that any suspicion of cheating or misconduct may result in disqualification.',
        'Avoid any external assistance, such as notes, books, or communication with others.',
        'Failure to adhere to test environment rules may result in non compliance penalties, including disqualification.',
        '### Exam Sections:',
        'You can start your test by selecting one of the sections from below, and clicking the "Start Exam" button.',
        'You can navigate across the sections and questions during assessment.',
        'You have the flexibility to complete each section individually.',
      ],
      guidelinesForImageCapturePupup: [
        '##     Consent Guideline for Audio & Video Recording and Image Capture',

        `By checking the box below, I hereby provide my voluntary and informed consent for the collection and processing of audio, video, and image data during my participation in ze[code]'s assessment platform. I understand and agree to the following terms:`,
        '### Purpose of Data Collection:',
        ' The audio and video recording, as well as image capture, is conducted for the sole purpose of facilitating the assessment process and assisting in the hiring process.',
        '### Data Usage:',
        ' The recorded audio and video will be used for evaluating assessment performance. Still images may be captured for identification purposes.',
        '### Data Security:',
        ' ze[code] team ensures the secure storage and handling of all recorded data and images. Access is restricted to authorized personnel involved in the hiring process.',
        '### Retention Period:',
        ' Recorded data and images will be retained for a year and will be deleted thereafter, unless required for legal or compliance purposes.',
        '### Questions or Concerns:',
        ' If I have any questions or concerns about the data collection process, I understand that I can contact the ze[code] team at $$contact-us$$.',
        '#### I acknowledge that I have read the terms outlined above, and I voluntarily consent to the collection and processing of audio, video, and image data as described.',
      ],
      guidelinesAcknowledge:
        'I acknowledge that I have read the guidelines and agree to comply with them.',
      guidelinesForImageCapture:
        'By participating in this assessment, you consent to the capture of audio/video for evaluation purposes.',
      guidelinesForImageCaptureSubText:
        'to read the guidelines in detail and check the box to indicate your acceptance.',

      testGuidelinesHeading: 'Test Guidelines',
      testWebcamAlert:
        'You will be monitored via webcam while taking the test. Please make sure that your webcam is connected and functional.',
      testSectionhopGuideline:
        'You can hop between any section during the course of your Assessment and the data will be saved.',
      //to do for later
      // assessment: 'Codathon',
      text: ['Welcome to your Software Developer assessment on Zecode.'],
      welcomeNote: 'Welcome to  ',
    },
    buttonContent: {
      next: 'Next',
      nextSection: 'Next Section',
      submit: 'Submit Exam',
    },
    submissionTexts: {
      content:
        'Thank you for participating. Your responses have been saved, and your submission has been received by the organisation.',
      submissionText: 'Exam successfully submitted',
    },
    candidateSubmissiontexts: {
      content: 'Thank you for taking part in the interview with',
      submissionText: 'Interview successfully ended',
      leaveInterviewText: 'You have left the interview.',
      rejoinText: 'You can rejoin the interview at any time. When you are ready to return,',
      rejoinSubText: 'click the button below to rejoin the interview.',
    },
    InterviewerSubmissiontexts: {
      content:
        'Thank you for participated in this interview session. Do not forgot to fill out the scorecard below',
      submissionText: 'Session ended',
    },
  },
  interview: {
    interviewStartPage: {
      interviewPlatform: 'Interview Platform',
      invitedText: 'You have been invited to attend an interview with',
      invitedLink: 'Please use the link below to attend the interview',
      interviewJoinButton: 'Click here to join the interview',
    },
    interviewMessages: {
      callDisconnected: 'Call Disconnected',
      callRejected: 'Call  Rejected',
      accessGranted: 'Access Granted',
      accessDenied: 'Access Denied',
      questionDeleted: 'Interviewer deleted the question',
      stoppedSharing: 'Candidate stopped sharing',
      connectionLost: 'Connection Lost. Please click on OK and initiate again.',
      shareScreenPermissions: 'The candidate has started sharing their screen. Click here to view.',
      shareScreenPermissionHeader: ' Screen Sharing Request',
      accessPermissions: 'Please allow the interviewer to access the editor.',
      candidateLeft: 'Candidate left the Interview',
      interviewerLeft: 'Interviewer left the Interview',
      callEnded: 'The interviewer has ended the call',
      viewScreenRejected: 'Interviewer rejected the view share screening',
    },
  },
  popUp: {
    buttonContent: {
      cancel: 'Cancel',
      no: 'No',
      submit: 'Submit',
      yes: 'Yes',
    },
    popUpMessages: {
      allotedTimeText: 'Your alloted time is completed',
      allotedTimeTextDescription: 'Test will end once you submit',
      backToAssessmentDescription:
        ' This action will navigate you back to the section menu, allowing you to revisit and update your responses as needed',
      backToAssessmentText: 'Are you sure you want to go back?',
      changeIDEdescription: 'If you click Yes, you will lose your current code.',
      changeIDEText: 'Are you sure you want to change the language?',
      changeSectionDescription:
        "Don't worry your responses will be saved. You may return to this section at any time to update your answers.",
      changeSectionText: 'Are you sure you want to switch to',
      interviewSubmissionText: 'Are you sure you want to end this interview?',
      deleteQuestionText: 'Are you sure you want to delete the question?',
      endCallText: 'Are you sure you want to end this video call?',
      interviewLeave: 'Are you sure you want to leave the interview?',
      interviewSubmissionDescription: 'The interview will end if you click on yes',
      questionDeletionText: `If you click yes, you will lose your current question along with its response`,
      endCallMessage: `If you click "Yes," both the video call and screen sharing will be terminated.`,
      interviewLeaveText: "Don't worry you can join the interview again.",
      nextSectionText: 'Are you sure you want to go to the next section?',
      resetCodeDescription: 'If you click Yes, you will lose your current code.',
      resetCodeText: 'Are you sure you want to reset the code?',
      submitCodeText: 'Are you sure you want to submit the code?',
      submitCodeDescription: 'You may return to this question at any time to update your answer',
      submitExamDescription:
        'Clicking "Submit" will conclude the assessment screening process, saving your responses, and preventing further access to the assessment.',
      submitExamText: 'Are you sure you want to submit your assessment?',
      cameraWarning: 'Please switch on the camera',
      noFaceWarning: 'Please make sure that your camera is enabled and facing you.',
      multipleFacesWarning: 'Multiple Faces Detected',
      proctorNote: 'Click the OK button after activating your webcam.',
      proctorNoteMultipleFaces:
        'Please click on OK button after ensuring that only one face is detected',
    },
    popupTitles: {
      changeSection: 'Change Section',
      nextSection: 'Next Section',
      resetCode: 'Reset Code',
      submitCode: 'Submit Code',
      goBack: 'Go Back',
      changeIDE: 'Change IDE',
      submitExam: 'Submit Assessment',
      timeCompleted: 'Time Completed',
      endInterview: 'End Interview',
      deleteQuestion: 'Delete Question',
      interviewLeave: 'Leave Interview',
      endCall: 'End Video Call',
      accessRequest: 'Access Request',
      viewScreen: 'Screen Sharing Request',
      connectionLost:'Connection Lost',
      attention:'Attention'
    },
  },
};
