import React, { FC, useState } from 'react';

type ThemeContext = { themeColor: string; setThemeColor: (color: string) => void };

export const ThemeContext = React.createContext<ThemeContext>({} as ThemeContext);

interface ThemeContextProps {
  children: React.ReactNode;
}

export const ThemeProvider: FC<ThemeContextProps> = ({ children }) => {
  const [themeColor, setThemeColor] = useState<string>('');

  return (
    <ThemeContext.Provider value={{ themeColor, setThemeColor }}>{children}</ThemeContext.Provider>
  );
};
export default ThemeProvider;
