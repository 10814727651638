import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// CSS
import '../../css/assessmentCompleted.css';

// Static
import { commonStrings } from '../../static/commonStrings';
import { Box, Button, SxProps } from '@mui/material';
import { getEnterpriseById } from '../../api/requests/enterprise';
import { setProfileDetailSettings } from '../../util/util';
import { ThemeConfigurationType } from '../Types/Index';
import { getEnterpriseTheme } from '../../api/requests/themeConfiguration';
import { getInterviewCandidate, updateInterviewStatus } from '../../api/requests/interview';
import ZecodeUrl from '../common/ZecodeUrl';
import { ZecodeText } from '../common/zecodeText';
import { RouterNames, sectionStatus } from '../../static/constants';
import {
  FooterStyles,
  ImageStyles,
  InterviewTextStyles,
  LogoWrapper,
  StyledContainerStyles,
} from '../common/styles';

const zecodeLogo = '/images/zecodeSideBarLogo.svg';

const LeaveInterview = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [enterpriseLogo, setEnterpriselogo] = useState('');
  const [status, setStatus] = useState();
  const [enterpriseId, setEnterpriseId] = useState();
  const [profileDetails, setProfileDetails] = useState<ThemeConfigurationType>();

  const fetchInterviewDetails = async () => {
    if (id) {
      const response = await getInterviewCandidate({ id });
      setEnterpriseId(response?.data?.data?.enterpriseId);
      response && response.data && response.data.data && setStatus(response?.data?.data?.status);
      const enterprise = await getEnterpriseById(enterpriseId!);
      setEnterpriselogo(enterprise?.data?.data?.imageURL);
    }
  };

  const fetchProfileDetails = async () => {
    const response = await getEnterpriseTheme(enterpriseId!);
    response &&
      response?.data &&
      response?.data?.data &&
      setProfileDetailSettings(response?.data?.data);
    setProfileDetails(response?.data?.data);
  };

  const updateStatus = async () => {
    try {
      id && updateInterviewStatus({ id, status: sectionStatus.inProgress });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickRejoinHandler = async () => {
    if (id) {
      const response = await getInterviewCandidate({ id });
      if (response?.data?.data?.status === sectionStatus.inProgress) {
        navigate(`${RouterNames.interview}/${id}`);
      } else if (response?.data?.data?.status === sectionStatus.submitted) {
        navigate(`${RouterNames.interviewCompleted}/${id}`);
      } else if (response?.data?.data?.status === sectionStatus.noShow) {
        await updateStatus();
        navigate(`${RouterNames.interview}/${id}`);
      }
    }
  };

  useEffect(() => {
    fetchInterviewDetails();
    fetchProfileDetails();
    status === sectionStatus.submitted && navigate(`${RouterNames.interviewCompleted}/${id}`);
  }, [enterpriseId]);

  return (
    <Box sx={StyledContainerStyles}>
      <Box sx={LogoWrapper}>
        <img
          src={
            profileDetails?.imageURL
              ? profileDetails?.imageURL
              : enterpriseLogo
                ? enterpriseLogo
                : zecodeLogo
          }
          alt='logo'
          style={ImageStyles}
        />
      </Box>
      <Box>
        <Box sx={{ ...InterviewTextStyles, textTransform: 'unset' }}>
          {commonStrings.assessment.candidateSubmissiontexts.leaveInterviewText}
        </Box>
        <Box sx={rejoinText}>{commonStrings.assessment.candidateSubmissiontexts.rejoinText}</Box>
        <Box sx={{ ...rejoinText, marginBottom: '20px' }}>
          {commonStrings.assessment.candidateSubmissiontexts.rejoinSubText}
        </Box>
        <Box sx={InterviewTextStyles}>
          <Button onClick={onClickRejoinHandler} sx={rejoinButton}>
            Rejoin interview
          </Button>
        </Box>
      </Box>
      <Box sx={FooterStyles}>
        <ZecodeUrl />
        <ZecodeText />
      </Box>
    </Box>
  );
};

export default LeaveInterview;

const rejoinText: SxProps = {
  fontFamily: 'var(--bodyText-font-family)',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#ffffff',
  whiteSpace: 'pre',
};

const rejoinButton: SxProps = {
  height: '100%',
  textTransform: 'capitalize',
  marginTop: '14px',
  fontSize: '14px',
  color: '#ffffff',
  borderRadius: '10px',
  border: ' 0.5px solid var(--first-color)',
  fontFamily: 'var(--bodyText-font-family);',
  fontWeight: 500,
  background: 'transparent',
  '&:hover': {
    border: ' 0.5px solid var(--first-color)',
    boxShadow: '0 0 10px var(--first-color)',
    background: 'transparent',
  },
};
