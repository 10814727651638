import React, { useState, useEffect } from 'react';
import { Snackbar, Alert, AlertColor, SxProps } from '@mui/material';

const SnackbarComponent = ({
  open,
  close,
  message,
  setSnackbar,
  snackbarType,
  isInterviewer,
}: {
  open?: boolean;
  close?: () => void;
  message?: string;
  setSnackbar?: (open: boolean) => void;
  snackbarType?: AlertColor;
  isInterviewer?: boolean;
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(open);

  useEffect(() => {
    if (open && !close && setSnackbar) {
      const timeout = setTimeout(() => {
        setSnackbar(false);
        setSnackbarOpen(false);
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [open, close, setSnackbar]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={4000}
      open={snackbarOpen}
      sx={{
        ...(!isInterviewer && {
          marginBottom: '100px',
        }),
      }}
      onClose={close}
    >
      <Alert variant='filled' onClose={close} severity={snackbarType} sx={alertSx}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;

const alertSx: SxProps = {
  width: '100%',
  background: 'var(--first-color)',
  display: 'flex',
  alignItems: 'center',
};
